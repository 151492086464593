import styled from 'styled-components'
import Img from 'gatsby-image'
import {
  breakpoint,
  Heading2Styles,
  ParagraphStyles,
  CaseHeading2Styles,
  Halign
} from '../../layout/mixins'
import Video from '../Video'
import CustomLink from '../CustomLink'
import AnimatedArrow from '../AnimatedArrow/animated-arrow'

export const Grid = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  
  ${breakpoint.up('tablet')`
    grid-template-columns: 40% 20% 40%;
    grid-template-rows: calc(40vw * 0.69) auto auto;
  `}
`
const GridItem = styled.div`
  min-height: 200px;
  border: 1px solid black;
`
export const BusinessModelItem = styled(GridItem)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.blockpaddingMobile} ${theme.pagepaddingMobile}`};
  border-left: 0;

  ${breakpoint.up('tablet')`
    padding: ${({ theme }) => theme.gutterLarge};
    grid-row: span 2;
    grid-column: span 2;
  `}

  ${breakpoint.up('max')`
    padding: ${({ theme }) => theme.gutterXLarge};
  `}
`
export const MediaItem = styled(GridItem)`
  position: relative;
  height: 100vw;

  ${breakpoint.up('tablet')`
    height: 100%;
  `}
`

export const CircleText = styled.div`
  position: absolute;
  width: 12rem;
  height: 12rem;
  top: 0;
  right: ${({ theme }) => theme.gutterXLarge};
  z-index: 1;
  transform: translateY(-40%);
`

export const CTA1Item = styled(GridItem)`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.blockpaddingMobile} ${theme.pagepaddingMobile}`};
  border-right: 0;
  
  ${breakpoint.up('tablet')`
    padding: ${({ theme }) => theme.gutterLarge};
  `}

  ${breakpoint.up('max')`
    padding: ${({ theme }) => theme.gutterXLarge};
  `}

  p {
    ${Heading2Styles}
    line-height: 1.136em;
  }
`
export const CTA2Item = styled(GridItem)`
  border-left: 0;
`

export const FeaturedCaseItem = styled(GridItem)`
  height: 100vw;
  position: relative;
  border-right: 0;

  ${breakpoint.up('tablet')`
    height: 100%;
    min-height: calc(60vw * 0.63);
    grid-column: span 2;
  `}
`
export const BusinessModelTitle = styled.h2`
  ${Heading2Styles}
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const BusinessModelDescription = styled.div`
max-width: ${({ theme }) => theme.maxTextWidth};

  p {
    ${ParagraphStyles}
  }
`

export const StyledVideo = styled(Video)`
    ${breakpoint.up('tablet')`
      overflow: hidden;

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
      }
    `}
`

export const MediaImageWrapper = styled(Img)`
  width: 100%;
  height: 100%;
`

export const CTA2Title = styled.h2`
  ${Heading2Styles}
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const CTA2Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
`

export const CTA2Description = styled.div`
  max-width: calc(100% - ${({ theme }) => theme.animatedArrowWidth});

  ${breakpoint.up('phone')`
    max-width: ${({ theme }) => theme.maxTextWidth};
  `}

  ${breakpoint.up('tablet')`
    width: 50%;
  `}

  p {
    ${ParagraphStyles}
  }
`

export const CTA2Link = styled(CustomLink)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: ${({ theme }) => `${theme.blockpaddingMobile} ${theme.pagepaddingMobile}`};
  background: ${({ theme }) => theme.colorSould}; 

  ${breakpoint.up('tablet')`
    padding: ${({ theme }) => theme.gutterLarge};
  `}

  ${breakpoint.up('max')`
    padding: ${({ theme }) => theme.gutterXLarge};
  `}
`

export const FeaturedCaseContent = styled(CustomLink)`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.colorWhite};

`

export const FeaturedCaseTitle = styled.h2`
  position: absolute;
  bottom: ${({ theme }) => theme.gutter};
  left: ${({ theme }) => theme.gutter};
  max-width: calc(100% - ${({ theme }) => theme.animatedArrowWidth});
  ${CaseHeading2Styles}

  ${breakpoint.up('phone')`
    max-width: none;
  `}

  ${breakpoint.up('tablet')`
    bottom: ${({ theme }) => theme.gutterLarge};
    left: ${({ theme }) => theme.gutterLarge};
  `}

  ${breakpoint.up('max')`
    bottom: ${({ theme }) => theme.gutterXLarge};
    left: ${({ theme }) => theme.gutterXLarge};
  `}
`

export const FeaturedCaseClient = styled.span`
  position: absolute;
  top: ${({ theme }) => theme.gutter};
  left: ${({ theme }) => theme.gutter};
  fill: ${({ theme }) => theme.colorWhite};
  transform-origin: top left;
  transform: rotate(-90deg) translateX(-100%);

  ${breakpoint.up('tablet')`
    top: ${({ theme }) => theme.gutterLarge};
    left: ${({ theme }) => theme.gutterLarge};
  `}

  ${breakpoint.up('max')`
    top: ${({ theme }) => theme.gutterXLarge};
    left: ${({ theme }) => theme.gutterXLarge};
  `}
`

export const FeaturedCaseAnimatedArrow = styled(AnimatedArrow)`
  position: absolute;
  bottom: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
  fill: ${({ theme }) => theme.colorWhite};

  ${breakpoint.up('tablet')`
    bottom: ${({ theme }) => theme.gutterLarge};
    right: ${({ theme }) => theme.gutterLarge};
  `}

  ${breakpoint.up('max')`
    bottom: ${({ theme }) => theme.gutterXLarge};
    right: ${({ theme }) => theme.gutterXLarge};
  `}

  .line {
    stroke: ${({ theme }) => theme.colorWhite};
  }
`