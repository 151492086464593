export const blockData = (blocks, blockName) => blocks
  .filter((block) => block['__typename'] === blockName)[0]

export const trimUnits = (value) => value.match(/(\d+(\.\d+)?)/)[0]

export const isExternalUrl = (url) => {
  const pattern = /^((http|https|www):\/\/)/
  return pattern.test(url)
}

export const isHashUrl = (url = '') => url.includes('#')

export const isEmail = (email) => {
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(email)
}

export const getIframeSrc = (type = 'youtube', providerUid) => {
  const providers = {
    youtube: {
      url: 'https://www.youtube.com/embed/',
      parameters: '?autoplay=1'
    },
    vimeo: {
      url: 'https://player.vimeo.com/video/',
      parameters: '?autoplay=1'
    }
  }

  return `${providers[type].url}${providerUid}${providers[type].parameters}`
}

export const outsideClick = (targets, callback) => {
  const handler = (evt) => {
    const insideElements = Array.from(document.querySelectorAll(targets))
    let targetElement = evt.target // clicked element

    do {
      if (insideElements.includes(targetElement)) {
        // Click inside.
        // Do nothing, just return.
        return
      }
      // Go up the DOM.
      targetElement = targetElement.parentNode
    } while (targetElement)

    // Clicked outside. Do something useful here.
    if (typeof callback === 'function') {
      callback()
    }
  }

  const listen = () => {
    document.addEventListener('click', handler)
  }

  const remove = () => {
    document.removeEventListener('click', handler)
  }

  return {
    listen,
    remove
  }
}

export const removeNonNumeric = (str) => str.replace(/[^\d.]/g, '')

export const addZero = (number) => {
  if (number < 10) {
    return `0${number}`
  }

  return number
}

export const isSSR = typeof window === 'undefined'

export const generateTags = (tags = false, content = false, date = false) => {
  let generatedtags = []

  if (tags) {
    generatedtags = [
      ...generatedtags,
      ...tags.map(({ title }) => title)
    ]
  }

  if (date) {
    generatedtags = [
      ...generatedtags,
      new Intl.DateTimeFormat('sv-SE', {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      }).format(new Date(date))
    ]
  }

  if (content) {
    const readingTime = Math.ceil(content.map((text) => text.content && text.content) // No filtering of content types since we are only storing texts in the content field
      .join(' ')
      .replace(/[^a-öA-Ö ]/gi, '')
      .split(' ')
      .filter(Boolean).length / 200) // We read about 200 words a minute

    generatedtags = [
      ...generatedtags,
      `${readingTime} min read`
    ]
  }

  return generatedtags
}