import React, { useEffect, useState } from 'react'
import {
  Wrapper,
  ImageWrapper,
  StyledVideo,
  Inner,
  Content,
  Heading,
  Arrow
} from './styles'
import theme from '../../layout/theme'
import { isSSR } from '../../utils/helpers'

const StartHero = ({
  title,
  backgroundMedia,
  backgroundType: bgType,
  backgroundColor,
  backgroundPoster
}) => {
  
  const jsonBgType = JSON.parse(bgType)
  const dynamicBackgroundType = Array.isArray(jsonBgType) && jsonBgType.length > 0 ? jsonBgType[0] : 'color'
  const [backgroundType, setBackgroundType] = useState('color')

  const checkMedia = (event) => {
    if(event.matches) {
      setBackgroundType('color')
    }
    else {
      setBackgroundType(dynamicBackgroundType)
    }
  }

  // Run only on client side
  if(!isSSR) {
    const match = window.matchMedia(`(max-width: ${theme.breakpoints.phone}px)`);

    useEffect(() => {
      match.addListener(checkMedia)
      checkMedia(match)
      return () => {
        match.removeListener(checkMedia)
      }
    }, [])
  }

  const backgroundImage = backgroundType === 'image' ? {
    ...backgroundMedia?.fluid,
    sizes: '100vw'
  } : {}

  return (
    <Wrapper
      backgroundColor={backgroundColor}
    >

      {backgroundType === 'image' ? (
        <ImageWrapper fluid={backgroundMedia?.fluid} />
       ) : null}

      {backgroundType === 'video' ? (
        <StyledVideo
          url={backgroundMedia?.url}
          caption="Video caption"
          autoPlay={true}
          poster={backgroundPoster}
        />
      ) : null}

      <Inner>
        <Arrow />
        <Content>
          <Heading dangerouslySetInnerHTML={{ __html: title }} />
        </Content>
      </Inner>
    </Wrapper>
  )
}

export default StartHero