import React from 'react'
import { createGlobalStyle } from 'styled-components'
import {
  breakpoint, Heading1Styles, Heading2Styles, ParagraphStyles
} from './mixins'
import theme from './theme'

const RTEStyles = createGlobalStyle`
  .rte {
    h1, h2, h3, h4 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 1em;
    }

    p:last-child {
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;
    }

    ul, ol {
      padding-bottom: ${theme.gutterLarge};
      padding-left: 1em;
      &:last-child {
        padding-bottom: 0;
      }
    }

    ul li,
    ol li {
      ${ParagraphStyles}
      padding-bottom: ${theme.gutterSmall};
      padding-left: ${theme.gutterSmall};

      &:last-child {
        padding-bottom: 0;
      }
    }

    ol li {
      list-style-type: decimal;
    }

    ul li {
      position: relative;

      &:before {
        display: block;
        content: '';
        background-image: url("data:image/svg+xml;utf8,<svg width='24' height='16' viewBox='0 0 24 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M17.0931 0.929124L23.4571 7.29308C23.8476 7.68361 23.8476 8.31677 23.4571 8.7073L17.0931 15.0713C16.7026 15.4618 16.0695 15.4618 15.6789 15.0713C15.2884 14.6807 15.2884 14.0476 15.6789 13.657L20.3358 9.00019H0.75V7.00019H20.3358L15.6789 2.34334C15.2884 1.95281 15.2884 1.31965 15.6789 0.929124C16.0695 0.538599 16.7026 0.538599 17.0931 0.929124Z' fill='black'/></svg>");
        background-repeat: no-repeat;
        background-size: 50%;
        width: 24px;
        height: 16px;
        position: absolute;
        top: 0;
        left: -1rem;
        transform: translateY(50%);

        ${breakpoint.up('phone')`
          background-size: 75%;
        `}

        ${breakpoint.up('desktop')`
          background-size: contain;
        `}
      }

    }

    blockquote {
      padding: ${theme.gutterLarge} 0;
      &:last-child {
        padding-bottom: 0;
      }
    }

    blockquote p {
      ${Heading2Styles}

      position: relative;
      z-index: ${theme.zLevel3};
      margin: 0 auto;
      max-width: 90%;
      padding-top: 4rem;

      ${breakpoint.up('phone')`
        padding-top: 0;
        padding-left: 8.5rem;
        font-size: 3.2rem;
      `}

      &:before {
        font-size: 4em;
        position: absolute;
        content: '“';
        top: 0;
        left: 0;
        transform: translateY(150%);
        z-index: ${theme.zLevel2};

        ${breakpoint.up('phone')`
          font-size: 4.5em;
          left: 20px;
          transform: translateY(170%);
        `}
      }

      &:after {
        ${Heading1Styles}
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 2em;
        height: 1em;
        background: ${theme.colorAccent};
        transform: translateY(-10%);
        z-index: -1;

        ${breakpoint.up('phone')`
          width: 3em;
          height: 0.9em;
        `}
      }
    }
  }
`
export default RTEStyles