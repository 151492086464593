import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import {
  StyledHeader,
  Info,
  InfoItem,
  HamburgerGroup,
  Hamburger,
  Close,
  themes,
  StyledLogo,
  Quote,
  TypeFormButton
} from './styles'
import Menu from '../Menu/menu'
import CustomLink from '../CustomLink'

const Header = ({ theme }) => {
  const {
    links,
    content
  } = useStaticQuery(query).datoCmsHeader
  return (
    <StyledHeader className={`header--${theme}`}>
      <Menu menuItems={links} />
      <CustomLink to="/">
        <StyledLogo />
      </CustomLink>
      <Info>
        {content.map((info, i) => (
          <InfoItem key={i} dangerouslySetInnerHTML={{ __html: info.text}} />
        ))}
        <Quote>
        <TypeFormButton id="GzzlALgN">
            Get quote
        </TypeFormButton>
        </Quote>
      </Info>
      <HamburgerGroup onClick={() => {
        document.body.classList.toggle('menu-open')
      }}>
        <Hamburger />
        <Close />
      </HamburgerGroup>
    </StyledHeader>
  )
}

Header.propTypes = {
  theme: PropTypes.string
}

Header.defaultProps = {
  theme: 'light'
}

export default Header

export const query = graphql`
  query HeaderQuery {
    datoCmsHeader {
      links {
        title
        url
      }      
      content {
        text
      }
    }    
  }
`
