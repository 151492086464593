import React from 'react'
import { graphql } from 'gatsby'
import {
  Wrapper, StyledBackground, Content, Text
} from './styles'

const FullWidthImage = ({ data }) => {
  const { coverText, image } = data;
  const backgroundImage = {
    ...image.fluid,
    sizes: '100vw'
  }

  return (
    <Wrapper>
      <StyledBackground fluid={backgroundImage}>
        {coverText && (
        <Content>
          <Text>{coverText}</Text>
        </Content>
        )}
      </StyledBackground>
    </Wrapper>
  )
}
export default FullWidthImage


export const FULL_WIDTH_IMAGE_FRAGMENT = graphql`
  fragment FullWidthImageFragment on DatoCmsFullWidthImage {
    id
    coverText
    image {
      fluid(maxWidth: 2560, imgixParams: { fm: "jpg", auto: "compress" }, sizes: "375, 750, 850, 1024, 1700, 2048, 2560") {
        ...GatsbyDatoCmsFluid
      }          
    } 
  }
`