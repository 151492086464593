import styled from 'styled-components'
import ArrowIcon from '../../layout/icons/horizontal-short-arrow.svg'

export const Arrow = styled(ArrowIcon)`
  fill: ${({ theme }) => theme.textcolorPrimary};
  transition: fill ${({ theme }) => theme.transitionRegular};
  width: ${({ theme }) => theme.animatedArrowWidth};
  height: 16px; 
  
  .line {
    transition: all ${({ theme }) => theme.transitionRegular};
    transform-origin: -100% 50%;
    fill:none;
    stroke:#000;
    stroke-width:2;
    stroke-dashoffset: 0;
    stroke-dasharray: 55;
  }

  ${({ hoverTrigger }) => `
    ${hoverTrigger}:hover & {
      fill: ${({ theme }) => theme.colorAccent};
    
      .line {
        stroke: ${({ theme }) => theme.colorAccent};
        stroke-dasharray: 100;
      }
    }
  `}
`