import React, { Component } from 'react'
import {
  StyledSlider,
  NextArrow,
  PreviousArrow
} from './styles'

class SimpleSlider extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <PreviousArrow />,
      nextArrow: <NextArrow />,
      afterChange: (index) => {
        if (typeof this.props.onSlideChange === 'function') {
          this.props.onSlideChange(index)
        }
      }
    }
    return (
      <>
        <StyledSlider {...this.props} {...settings}>
          {this.props.children}
        </StyledSlider>
      </>
    )
  }
}

export default SimpleSlider