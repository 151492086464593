import React, { Component } from 'react'
import {
  StyledModal,
  Overlay,
  Content,
  Close
} from './styles'
import Portal from '../Portal'

class Modal extends Component {
  componentDidMount() {
    document.body.style.top = `-${window.scrollY}px`
    document.body.classList.add('modal-open')
  }

  componentWillUnmount() {
    const scrollY = document.body.style.top
    document.body.classList.remove('modal-open')
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1)
  }

  render() {
    const { children, toggle, modifier = '' } = this.props

    const modalModifier = modifier ? ` ${modifier}` : ''
    return (
      <Portal>
        <StyledModal className={`modal${modalModifier}`}>
          <Close onClick={toggle} />
          <Overlay onClick={toggle} />
          <Content>
            {children}
          </Content>
        </StyledModal>
      </Portal>
    )
  }
}

export default Modal