import styled from 'styled-components'
import Img from 'gatsby-image'
import { breakpoint } from '../../../layout/mixins'
import EnlargeIcon from '../../../layout/icons/enlarge.svg'
import { NextArrow, PreviousArrow } from '../../Slider/styles'


export const StyledImg = styled(Img)`
  width: 100%;
  height: 0;
  padding-top: 82%; //by design
`


export const Enlarge = styled(EnlargeIcon)`
  fill: ${({ theme }) => theme.colorWhite};
  position: absolute;
  bottom: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
  width: 1em;
  height: 1em;
  opacity: 1;
  transition: opacity ${({ theme }) => theme.transitionRegular};

  ${breakpoint.up('phone')`
    opacity: 0;
  `}
  
  ${({ visible }) => !visible && `
    display: none;
  `}
`

export const ImgWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:hover ${Enlarge} {
    opacity: 1;
  }
`

export const Content = styled.div`
  z-index: ${({ theme }) => theme.zLevel3};
  
  ${breakpoint.up('tablet')`
    padding: 40px;
    width: 100vw;
    height: 100vh;    
  `}

  ${breakpoint.down('phone')`
    ${NextArrow}, ${PreviousArrow} {
      display: none !important;
    }
  `}
`


export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  padding: 40px;


`

export const Image = styled.img`
  display: block;
  ${({ theme, imageWidth, imageHeight }) => {
    if (imageWidth > imageHeight) {
      return `
        --containerWidth: calc(100vw - 80px);
        --containerHeight: calc(100vh - 80px);
        width: var(--containerWidth);
        height: auto;
        max-height: var(--containerHeight);
        max-width: calc(var(--containerHeight) * ${imageWidth / imageHeight});
      `
    }

    return `
      .slick-slide img {
        width: unset;
      }
      --containerHeight: calc(100vh - 80px);
      height: calc(100vh - 80px);
    `
  }}
`
