import styled from 'styled-components'

export const Tags = styled.ul`
  margin-top: ${({ theme }) => theme.gutterSmall};
  display: flex;
  flex-direction: row;
`
export const Tag = styled.li`
  padding: 2px 10px;
  background: ${({ theme }) => theme.colorAccent};
  margin-right: ${({ theme }) => theme.gutterSmall};
  font-size: ${({ theme }) => theme.fontsizeSmall};
  &:last-child {
    margin-right: 0;
  }
`