import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { addZero } from '../../utils/helpers'
import {
  Wrapper,
  ImageWrapper,
  StyledVideo,
  Inner,
  Content,
  Number,
  HeadingGroup,
  Heading,
  Announcement,
  Arrow,
  NextCase,
  Numbers,
  CurrentCase,
  Total,
  NextCaseArrow,
  StyledCustomLink
} from './styles'

const SingleProjectHero = ({
  title,
  announcement,
  backgroundMedia,
  backgroundType: bgType,
  backgroundColor,
  allProjects,
  projectNumber,
  slug
}) => {

  const numberOfCases = allProjects.length
  const nextCase = allProjects[getNextIndex()]

  function getNextIndex() {
   if (projectNumber > (numberOfCases - 1)) return 0
   return projectNumber
  }

  const jsonBgType = JSON.parse(bgType)
  const backgroundType = Array.isArray(jsonBgType) && jsonBgType.length > 0 ? jsonBgType[0] : 'color'

  return (
    <Wrapper
      backgroundColor={backgroundType === 'color' && backgroundColor}
    >

      {backgroundType === 'image' ? <ImageWrapper fluid={backgroundMedia?.fluid} /> : null}

      {backgroundType === 'video' ? (
          <StyledVideo
            url={backgroundMedia?.url}
            caption="Video caption"
            autoPlay={true}
          />
      ) : null}      

      <Inner>
        <Arrow />
        <Content>
          <Announcement dangerouslySetInnerHTML={{ __html: announcement }} />
          <HeadingGroup>
            <Number>.{addZero(projectNumber)}</Number>
            <Heading dangerouslySetInnerHTML={{ __html: title }} />
          </HeadingGroup>
        </Content>
      </Inner>
      <NextCase>
        <Numbers>
          <CurrentCase>project {projectNumber}</CurrentCase>/<Total>{numberOfCases}</Total>
        </Numbers>
        <StyledCustomLink to={`/projects/${nextCase.slug}`}>
          <NextCaseArrow />
        </StyledCustomLink>
      </NextCase>
    </Wrapper>
  )
}

export default SingleProjectHero