import React from 'react'
import PropTypes from 'prop-types'
import { Tags, Tag } from './styles'

const uuidv1 = require('uuid/v1')

const TextTags = ({ tags, className }) => (
  <Tags>
    { tags.map((tag) => (
      <Tag
        key={uuidv1()}
        className={className}
      >
        {tag}
      </Tag>
    ))}
  </Tags>
)

export default TextTags

TextTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  className: PropTypes.string
}

TextTags.defaultProps = {
  className: null
}