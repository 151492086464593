import styled from 'styled-components'
import BackgroundImage from 'gatsby-image'
import {
  TextSmallStyles,
  CaseHeading2Styles,
  breakpoint,
  truncateOverflow,
  ParagraphStyles
} from '../../layout/mixins'
import ArrowIcon from '../../layout/icons/horizontal-short-arrow.svg'
import CustomLink from '../CustomLink'
import Video from '../Video'

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
`

export const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`

export const StyledVideo = styled(Video)`
    position: absolute;
    ${breakpoint.up('max')`
      overflow: hidden;

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    `}
`

export const Content = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const Image = styled(CustomLink)`
  position: relative;
  display: block;
  flex: 1;
  height: 100%;
`

export const Header = styled.div`
  position: relative;
  height: 0;
  padding-top: 56.25%;
  width: 100%;
  z-index: ${({ theme }) => theme.zLevel1};
`

export const HeaderContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.gutter};
`

export const Title = styled.h2`
  ${CaseHeading2Styles}
  color: ${({ theme }) => theme.colorWhite};
  transition: color ${({ theme }) => theme.transitionRegular};
  ${truncateOverflow(2)}

  ${Wrapper}:hover & {
    color: ${({ theme }) => theme.colorAccent};
  }
`

export const Number = styled.span`
  ${TextSmallStyles}
  font-family: ${({ theme }) => theme.fontfamilySecondary};
  font-display: swap;
  color: ${({ theme }) => theme.colorWhite};
  transition: color ${({ theme }) => theme.transitionRegular};

  ${Wrapper}:hover & {
    color: ${({ theme }) => theme.colorAccent};
  }
`

export const Footer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 2;
`

export const FooterBackground = styled.div`
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: 100%;
  transition: transform ${({ theme }) => theme.transitionRegular};
  transform-origin: bottom center;
  background: ${({ theme }) => theme.colorSould};

  ${Wrapper}:hover & {
    ${breakpoint.up('desktop')`
      transform: scale3d(1, 0, 0); 
    `}
  }
`

export const FooterContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.gutterSmall} 0`};
  transition: background ${({ theme }) => theme.transitionRegular}, color ${({ theme }) => theme.transitionRegular};
  
  ${breakpoint.up('tablet')`
    padding: ${({ theme }) => `${theme.gutterSmall} ${theme.gutter}`};
  `}

  ${Wrapper}:hover & {
    ${breakpoint.up('tablet')`
      color: ${({ theme }) => theme.colorAccent};
    `}
  }
`

export const FooterGroup = styled.div`
  ${breakpoint.up('tablet')`
    padding-right: ${({ theme }) => theme.gutterMedium};
  `}

  &:nth-of-type(2) {
    display: none;

    ${breakpoint.up('desktop')`
      display: block;
    `}
  }

  &:last-child {
    padding-right: 0;
  }
`

export const FooterItemTitle = styled.div`
  ${TextSmallStyles}
  text-decoration: underline;
`

export const FooterItem = styled.div`
  ${TextSmallStyles}
`

export const Arrow = styled(ArrowIcon)`
  fill: ${({ theme }) => theme.textcolorPrimary};
  transition: fill ${({ theme }) => theme.transitionRegular};
  
  .line {
    transition: all ${({ theme }) => theme.transitionRegular};
    transform-origin: -100% 50%;
    fill:none;
    stroke:#000;
    stroke-width:2;
    stroke-dashoffset: 0;
    stroke-dasharray: 55;
  }

  ${Wrapper}:hover & {
    ${breakpoint.up('tablet')`
      fill: ${({ theme }) => theme.colorAccent};
    
      .line {
        stroke: ${({ theme }) => theme.colorAccent};
        stroke-dasharray: 100;
      }
    `}
  }
`