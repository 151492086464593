import React from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Grid,
  GridItem
} from './styles'
import InsightCard from '../InsightCard/insight-card'

const InsightsGrid = ({
  insights,
  className,
  columns,
  hover
}) => (
  <Wrapper className={className}>
    <Grid
      columns={columns}
      quantity={insights.length}
    >
      {insights && insights.map((insight) => (
        <GridItem key={insight.id}>
          <InsightCard
            insight={insight}
            hover={hover}
          />
        </GridItem>
      ))}
    </Grid>
  </Wrapper>
)

export default InsightsGrid

InsightsGrid.propTypes = {
  columns: PropTypes.number,
  hover: PropTypes.bool,
  insights: PropTypes.array.isRequired
}

InsightsGrid.defaultProps = {
  columns: 2,
  hover: true
}