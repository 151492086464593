import styled from "styled-components"
import { Halign, BlockPaddingTop, breakpoint } from "../../layout/mixins"

export const Wrapper = styled(BlockPaddingTop)``

export const Inner = styled(Halign)``

export const Grid = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.up("tablet")`
    flex-direction: row;    
  `}
`

const GridItem = styled.div`
  width: 100%;
  flex: 1;

  ${breakpoint.up("tablet")`
    width: 50%;
  `}

  ${breakpoint.up("large")`
    width: auto;
  `}
`

export const GridItem1 = styled(GridItem)``

export const GridItem2 = styled(GridItem)`
  ${breakpoint.up("large")`
    flex: 2;
  `}
`

export const Title = styled.div`
  font-size: 1.375rem;
  text-decoration: underline;
  margin-bottom: ${({ theme }) => theme.gutter};
`

export const Text = styled.div`
  p {
    ${breakpoint.up("tablet")`
      font-size: 1.33rem;
    `}
  }
`

export const Text1 = styled(Text)`
  &:last-child {
    margin-bottom: ${({ theme }) => theme.gutter};
  }

  ${breakpoint.up("tablet")`
    margin-bottom: 0;
  `}
`

export const Text2 = styled(Text)``
