import React from 'react'
import styled from 'styled-components'
import LogoIcon from '../../layout/icons/logo.svg'
import { breakpoint } from '../../layout/mixins'

export const StyledLogo = styled(LogoIcon)`
  width: 8rem;
  height: 2.405rem;
  display: block;
  fill: ${({ theme }) => theme.textcolorSecondary};
  .header--light & {
    fill: ${({ theme }) => theme.textcolorPrimary};
  }
`

const Logo = ({ className }) => {
  return (
      <StyledLogo className={className} />
  )
}

export default Logo