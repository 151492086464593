import styled from 'styled-components'
import Img from 'gatsby-image'
import {
  Halign,
  BlockPaddingTop,
  ParagraphStyles,
  breakpoint,
  TextSmallStyles
} from '../../layout/mixins'
import Divider from '../Divider/divider'
import Logo from '../Logo/logo'

export const Wrapper = styled(BlockPaddingTop)`
  padding-bottom: ${({ theme }) => theme.gutter};
`

export const Inner = styled(Halign)`
  .template-projects &, .template-single-project & {
    padding-left: ${({ theme }) => theme.gutterSmall};
    padding-right: ${({ theme }) => theme.gutterSmall};
  }  
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: ${({ theme }) => theme.gutterSmall};

  ${breakpoint.up('tablet')`
    grid-template-columns: 1fr 1fr;
  `}

  ${breakpoint.up('large')`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`

export const GridItem = styled.div`
  &:last-child {
    ${breakpoint.up('large')`
      display: flex;
      justify-content: flex-end;
    `}
  }


  p {
    ${TextSmallStyles}
  }

  ${breakpoint.up('phone')`
    p {
      ${ParagraphStyles}
    }
  `}
`

export const StyledDivider = styled(Divider)`
  margin-bottom: ${({ theme }) => theme.gutter};

  .template-projects &, .template-single-project & {
    padding-left: ${({ theme }) => theme.gutterSmall};
    padding-right: ${({ theme }) => theme.gutterSmall};
  }
`

export const StyledLogo = styled(Logo)`
  fill: ${({ theme }) => theme.textcolorPrimary};
  margin-top: ${({ theme }) => theme.gutter};
  
  ${breakpoint.up('phone')`
    margin-top: 0;
  `}
`