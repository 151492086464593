import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import SimpleHero from "../../components/SimpleHero/simple-hero"
import InfoGrid from "../../components/InfoGrid/info-grid"
import JobsList from "../../components/JobsList/jobs-list"
import { JobsInfoGrid } from "./styles"

const JobsPage = ({ data }) => {
  const { title, sections, seoMetaTags } = data?.page
  const jobs = data?.jobs.edges.map(item => ({ ...item.node }))

  return (
    <Layout seo={seoMetaTags.tags} template="single-project">
      <SimpleHero title={title} />

      <InfoGrid title1={sections[0].title} text2={sections[0].text} />

      <JobsList jobs={jobs} />

      <JobsInfoGrid text2={sections[1].text} />
    </Layout>
  )
}

export default JobsPage

export const query = graphql`
  query JobsPageQuery {
    page: datoCmsJobsPage {
      title
      sections {
        text
        title
      }
      seoMetaTags {
        tags
      }
    }
    jobs: allDatoCmsJob {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`
