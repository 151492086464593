import React from 'react';
import CaseImage from '../../components/blocks/CaseImage/case-image.js';
import MoodImage from '../../components/blocks/MoodImage/mood-image.js';
import TextContent from '../../components/blocks/TextContent/text-content.js';
import Video from '../../components/blocks/Video/video.js';
import RichText from '../../components/blocks/RichText/rich-text.js';
import Code from '../../components/blocks/Code/code.js';
import FullWidthImage from '../../components/blocks/FullWidthImage/full-width-image.js';
import InlineImages from '../../components/blocks/InlineImages/inline-images.js';

const BlockRenderer = ({ block }) => {
  return block.__typename === "DatoCmsCaseImage" ? (
    <CaseImage data={block} /> 
  ) : block.__typename === "DatoCmsTextContent" ? (
    <TextContent data={block} /> 
  ) : block.__typename === "DatoCmsMoodImage" ? (
    <MoodImage data={block} /> 
  ) : block.__typename === "DatoCmsVideo" ? (
    <Video data={block} /> 
  ) : block.__typename === "DatoCmsRichTextContent" ? (
    <RichText data={block} /> 
  ) : block.__typename === "DatoCmsCode" ? (
    <Code data={block} /> 
  ) : block.__typename === "DatoCmsFullWidthImage" ? (
    <FullWidthImage data={block} /> 
  ) : block.__typename === "DatoCmsInlineImage" ? (
    <InlineImages data={block} /> 
  ) : (
    null
  )
}

export default BlockRenderer