import React from "react"
import PropTypes from "prop-types"
import {
  Wrapper,
  Inner,
  Grid,
  GridItem1,
  GridItem2,
  Title,
  Text1,
  Text2,
} from "./styles"

const InfoGrid = ({
  title1,
  text1,
  list1,
  title2,
  text2,
  list2,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <Inner>
        <Grid>
          <GridItem1>
            {title1 && <Title>{title1}</Title>}
            {text1 && <Text1 dangerouslySetInnerHTML={{ __html: text1 }} />}
            {list1.length > 0 && list1.map(item => <Text1 key={item}>{item}</Text1>)}
          </GridItem1>
          <GridItem2>
            {title2 && <Title>{title2}</Title>}
            {text2 && <Text2 dangerouslySetInnerHTML={{ __html: text2 }} />}
            {list2.length > 0 && list2.map(item => <Text2 key={item}>{item}</Text2>)}
          </GridItem2>
        </Grid>
      </Inner>
    </Wrapper>
  )
}

InfoGrid.propTypes = {
  title1: PropTypes.string,
  text1: PropTypes.string,
  list1: PropTypes.array,
  title2: PropTypes.string,
  text2: PropTypes.string,
  list2: PropTypes.array,
}

InfoGrid.defaultProps = {
  title1: "",
  text1: "",
  list1: [],
  title2: "",
  text2: "",
  list2: [],
}

export default InfoGrid
