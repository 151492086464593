import styled from 'styled-components'
import PlaySVG from '../../layout/icons/play-button.svg'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  outline: none;
`

export const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ theme }) => theme.zLevel1};
  cursor: pointer;
  outline: none;

  .modal-open & {
    display: none;
  }
`

export const PlayIcon = styled(PlaySVG)`
  width: 4.22rem;
  height: 4.22rem;
  fill: ${({ theme }) => theme.colorAccent};
`