import React from 'react'
import { graphql } from 'gatsby'
import { StyledImg }
  from './styles'

const MoodImage = ({ data }) => (
  <StyledImg fluid={data?.image?.fluid} />
)

export default MoodImage


export const MOOD_IMAGE_BLOCK_FRAGMENT = graphql`
  fragment MoodImageBlockFragment on DatoCmsMoodImage {
    id
    image {
      fluid(maxWidth: 1280, imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }          
    } 
  }
`