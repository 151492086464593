import styled from 'styled-components'
import Img from 'gatsby-image'
import { InsightHalign } from '../../../layout/mixins'


export const StyledImg = styled(Img)`
  flex-basis: calc(50% - ${({ theme }) => theme.gutter}/2);

  &:only-child {
    flex-grow: 1;
  }
`

export const Wrapper = styled(InsightHalign)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`