import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Halign } from '../../layout/mixins'

const Wrapper = styled(Halign)`
  ${({ top, theme }) => top && `
  padding-top: ${theme.blockpadding};
  `};

  ${({ bottom, theme }) => bottom && `
  padding-bottom: ${theme.blockpadding};
  `};
`

const Line = styled.div`
  height: ${({ theme}) => theme.borderSize};
  width: 100%;
  background: ${({ theme}) => theme.colorBlack};
`

const Divider = ({ 
  top,
  bottom,
  className
}) => {
  return (
    <Wrapper
      top={top}
      bottom={bottom}
      className={className}
    >
      <Line />
    </Wrapper>
  )
}

Divider.propTypes = {
  top: PropTypes.bool,
  bottom: PropTypes.bool
}

Divider.defaultProps = {
  top: true,
  bottom: true
}

export default Divider