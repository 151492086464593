import styled from 'styled-components'
import {
  breakpoint,
  Heading2Styles,
  ParagraphStyles,
  ParagraphLargeStyles
} from '../../../layout/mixins'

export const Wrapper = styled.div`
  width: 100%;
  height: 0;
  padding-top: 82%; //by design
  display: flex;
  align-items: flex-end;
`

export const Text = styled.div`
  ${ParagraphStyles}
  font-size: 1.1rem;

  ${breakpoint.up('phone')`
    font-size: 2rem;
  `}

  ${breakpoint.up('tablet')`
    font-size: 1.2rem;
  `}

  ${breakpoint.up('desktop')`
    ${ParagraphLargeStyles}
    font-size: 1.57rem;
  `}

  ${breakpoint.up('max')`
    font-size: 2rem;
  `}

  ${breakpoint.up('xLarge')`
    ${Heading2Styles}
  `}
`