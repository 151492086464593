import React from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Inner,
  Grid,
  GridItem,
  Image,
  Name,
  WorkTitle,
  TitleStatus,
  Phone,
  Email
} from './styles'
import theme from '../../layout/theme'

const ContactGrid = ({ items, className }) => (
  <Wrapper className={className}>
    <Inner>
      <Grid>
        {items.map((item) => {
          if (item) {
            const {
              name,
              jobTitle,
              titleStatus,
              phone,
              mail,
              image: { fluid }
            } = item

            const { breakpoints } = theme

            const contactImage = {
              ...fluid,
              sizes: `(max-width: ${breakpoints.phone}px) calc(50vw - ${theme.pagepaddingMobile}), (max-width: ${breakpoints.large}px) calc(50vw - ${theme.pagepaddingDesktop}), calc(25vw - (${theme.pagepaddingMobile} / 2))`
            }

            return (
              <GridItem key={item.name}>
                <Image fluid={contactImage} />
                <Name>
                  {name}
                  {titleStatus && <TitleStatus as="span">{` (${titleStatus})`}</TitleStatus>}
                </Name>
                <WorkTitle>{jobTitle}</WorkTitle>
                <Phone
                  as="a"
                  href={`tel:${phone.trim().replace(/-/g, '')}`}
                >
                  {phone}
                </Phone>
                <Email
                  as="a"
                  href={`mailto:${mail}`}
                >
                  {mail}
                </Email>
              </GridItem>
            )
          }
        })}
      </Grid>
    </Inner>
  </Wrapper>
)

ContactGrid.propTypes = {
  items: PropTypes.array
}

ContactGrid.defaultProps = {
  items: []
}

export default ContactGrid
