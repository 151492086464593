import React from 'react'
import {
  Wrapper,
  Inner,
  Content,
  Heading,
  Arrow,
  Filter,
  FilterLink,
  Tag
} from './styles'
import CustomLink from '../CustomLink'

const ProjectListingHero = ({
  title,
  tags,
  activeTag
}) => {
  return (
    <Wrapper>
      <Inner>
        <Arrow />
        <Content>
          <Heading dangerouslySetInnerHTML={{ __html: title }} />
          <Filter>
            <Tag
              className={activeTag === 'All' ? 'active' : ''}
              key="All"
            >
              <FilterLink to="/projects">All</FilterLink>
            </Tag>
            {tags.map((tag) => {
              return (
                <Tag
                  className={activeTag == tag.serviceAreaName ? 'active' : ''}
                  key={tag.serviceAreaName}
                >
                  <FilterLink
                    to={`/projects/${tag.serviceAreaName.toLowerCase()}`}
                  >
                    {tag.serviceAreaName}
                  </FilterLink>
                </Tag>
              )
            })}
          </Filter>
        </Content>
      </Inner>
    </Wrapper>
  )
}

export default ProjectListingHero