import styled from 'styled-components'
import Slider from 'react-slick'
import sliderArrow from '../../layout/icons/slider-arrow.png'
import sliderArrowSvg from '../../layout/icons/slider-arrow.svg'

export const StyledSlider = styled(Slider)`
  position: relative;
  width: 100%;
  height: 100%;
  
  .slick-prev, .slick-next {
    z-index: ${({ theme }) => theme.zLevel1};
    width: 2.39rem;
    height: 2.61rem;
    display: block;
    opacity: 1;

    &:hover {
      opacity: 1;
    }
  }

  .slick-next {
    right: ${({ theme }) => theme.gutterMedium};
  }

  .slick-prev {
    left: ${({ theme }) => theme.gutterMedium};
    transform: rotate(180deg) translateY(50%);
  }
`

export const NextArrow = styled(sliderArrowSvg)`
  fill: ${({ theme }) => theme.colorAccent};
`

export const PreviousArrow = styled(sliderArrowSvg)`
  fill: ${({ theme }) => theme.colorAccent};
`