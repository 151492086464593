import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { generateTags } from '../../utils/helpers'
import TextTags from '../TextTags/text-tags'
import {
  Image,
  Content,
  Header,
  HeaderContent,
  Title,
  StyledBackgroundImage,
  StyledVideo,
  Footer,
  FooterBackground,
  FooterContent,
  FooterGroup,
  Arrow,
  WrapperWithHover,
  WrapperWithoutHover,
  themes
} from './styles'

import siteTheme from '../../layout/theme'

const InsightCard = ({
  insight,
  hover,
  backgroundAnimation
}) => {
  const {
    thumbnail,
    title,
    tags,
    meta: { firstPublishedAt },
    pageContent
  } = insight

  const cardTags = generateTags(tags, pageContent)

  const backgroundType = thumbnail.isImage ? 'image' : 'video'

  const Wrapper = hover ? WrapperWithHover : WrapperWithoutHover

  const theme = backgroundAnimation ? 'dark' : 'light'

  const { breakpoints: { phone } } = siteTheme

  const responsiveThumbnail = backgroundType === 'image' ? {
    ...thumbnail.fluid,
    sizes: `(max-width: ${phone}px) calc(100vw - (${siteTheme.pagepaddingMobile} * 2)), calc(67vw - (${theme.pagepaddingDesktop} / 2)`
  } : {}

  return (
    <ThemeProvider theme={themes[theme]}>
      <Wrapper>
        <Image to={`/insights/${insight.slug}`}>
          <Content>
            {backgroundType === 'image' ? (
              <StyledBackgroundImage style={{ position: 'absolute' }} fluid={responsiveThumbnail} />
            ) : null}

            {backgroundType === 'video' ? (
              <StyledVideo
                url={thumbnail.url}
                autoPlay
              />
            ) : null}

            <Header>
              <HeaderContent>
                <TextTags tags={cardTags} />
              </HeaderContent>
            </Header>
            <Footer>
              <FooterBackground backgroundAnimation={backgroundAnimation} />
              <FooterContent padding={!backgroundAnimation}>
                <FooterGroup>
                  <Title>{title}</Title>
                </FooterGroup>
                <FooterGroup>
                  <Arrow />
                </FooterGroup>
              </FooterContent>
            </Footer>
          </Content>
        </Image>
      </Wrapper>
    </ThemeProvider>
  )
}

export default InsightCard

InsightCard.propTypes = {
  insight: PropTypes.object.isRequired,
  hover: PropTypes.bool,
  backgroundAnimation: PropTypes.bool
}

InsightCard.defaultProps = {
  hover: true,
  backgroundAnimation: true
}

export const INSIGHT_CARD_FRAGMENT = graphql`
  fragment InsightCardFragment on DatoCmsInsight {
    id
    title
    slug
    tags {
      title
    }
    meta {
      firstPublishedAt
    }
    thumbnail {
      fluid(maxWidth: 2048, imgixParams: { fm: "jpg", auto: "compress" }, sizes: "375, 750, 850, 1024, 1440, 1700, 2048") {
        ...GatsbyDatoCmsFluid
      }
      url
      isImage
    }
    pageContent {
      ...RichTextContentFragment
    }
  }
`