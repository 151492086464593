
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {
  isExternalUrl,
  isHashUrl
} from '../../utils/helpers'

/**
 * Link that also works for external URL's
 */
class CustomLink extends Component {
  render() {
    const { to } = this.props
    return isExternalUrl(to) || isHashUrl(to) ? (
      <a
        href={to}
        {...this.props}
      />
    ) : <Link {...this.props} />
  }
}

CustomLink.propTypes = {
  to: PropTypes.string.isRequired
}

export default CustomLink