import styled from 'styled-components'
import { breakpoint, BlockPaddingTop } from '../../layout/mixins'

export const Wrapper = styled(BlockPaddingTop)``

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: ${({ theme }) => theme.gutterSmall};
  padding: ${({ theme }) => `0 ${theme.gutterSmall}`};

  ${breakpoint.up('tablet')`
    grid-template-columns: 1fr 1fr;
  `}
`

export const GridItem = styled.div``