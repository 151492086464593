import styled from 'styled-components'
import { Halign, breakpoint } from '../../layout/mixins'
import HamburgerIcon from '../../layout/icons/hamburger.svg'
import CloseIcon from '../../layout/icons/close.svg'
import Logo from '../Logo/logo'
import { SliderButton } from '@typeform/embed-react'

export const StyledHeader = styled.header`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: ${({ theme }) => theme.headerheight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.textcolorSecondary};
  border-bottom: ${({ theme }) => `${theme.borderSize} solid ${theme.textcolorSecondary}`};
  z-index: ${({ theme }) => theme.zLevel1};

  &.header--light {
    border-bottom: ${({ theme }) => `${theme.borderSize} solid ${theme.textcolorPrimary}`};
  }

  .menu-open & {
    z-index: ${({ theme }) => theme.zLevel6};
    border-color: ${({ theme }) => theme.textcolorSecondary};
  }
`

export const Info = styled.div`
  display: none;
  height: 100%;

  ${breakpoint.up('desktop')`
    display: flex;
  `}
`

export const Quote = styled.div`
`

export const TypeFormButton = styled(SliderButton)`
  margin-right: ${({ theme }) => theme.gutterLarge};
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  border-left: ${({ theme }) => `${theme.borderSize} solid ${theme.textcolorSecondary}`};
  transition: color ${({ theme }) => theme.transitionRegular}, background-color ${({ theme }) => theme.transitionRegular};


  &:hover {
    background: ${({ theme }) => theme.textcolorPrimary};
    color: ${({ theme }) => theme.textcolorSecondary};
  }


  .template-projects && {
    border-left: ${({ theme }) => `${theme.borderSize} solid ${theme.textcolorPrimary}`};
    color: ${({ theme }) => theme.textcolorPrimary};
    &:hover {
      color: ${({ theme }) => theme.textcolorSecondary};
    }
  }


`

export const InfoItem = styled.div`
  margin-right: ${({ theme }) => theme.gutterLarge};
  padding-top: 0.5rem;
  p {
    font-size: inherit;
  }

  a {
    color: ${({ theme }) => theme.textcolorSecondary};
    font-size: 0.78rem;
    line-height: 1.22em;
  }

  .header--light & a {
    color: ${({ theme }) => theme.textcolorPrimary};
  }
`

export const HamburgerGroup = styled.div`
  position: absolute;
  width: 76px;
  top: 0;
  right: 0;
  border-left: ${({ theme }) => `${theme.borderSize} solid ${theme.colorWhite}`};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoint.up('desktop')`
    display: none;
  `}

  .header--light & {
    border-color: ${({ theme }) => theme.textcolorPrimary};
  }

  .menu-open .header--light & {
    border-color: ${({ theme }) => theme.textcolorSecondary};
  }
`

export const Hamburger = styled(HamburgerIcon)`
  stroke: ${({ theme }) => theme.textcolorSecondary};
  
  .header--light & {
    stroke: ${({ theme }) => theme.textcolorPrimary};
  }

  .menu-open & {
    display: none;
  }
`

export const Close = styled(CloseIcon)`
  stroke: ${({ theme }) => theme.textcolorSecondary};
  display: none;

  .menu-open & {
    display: block;
  }
`

export const StyledLogo = styled(Logo)`
  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.gutter};
  transform: translateY(-50%);

  ${breakpoint.up('tablet')`
    left: ${({ theme }) => `calc(${theme.pagepaddingDesktop} + ${theme.gutter})`};
  `}

  ${breakpoint.up('desktop')`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}

  .menu-open & {
    fill: ${({ theme }) => theme.textcolorSecondary};
  }
`