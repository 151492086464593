import styled from 'styled-components'
import Background from 'gatsby-background-image'
import {
  BlockPaddingTopStyles, CaseHeading1Styles, breakpoint, InsightHalign
} from '../../../layout/mixins'

export const Wrapper = styled.div`
  ${BlockPaddingTopStyles}
`

export const StyledBackground = styled(Background)`
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoint.up('phone')`
    min-height: 800px;
  `}

`

export const Text = styled.h4`
  ${CaseHeading1Styles}
  text-decoration: none;
  color: ${({ theme }) => theme.colorAccent};
`

export const Content = styled(InsightHalign)`
  text-align: center;
  padding-top: 0;
`
