import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SimpleHero from '../../components/SimpleHero/simple-hero'
import InfoGrid from '../../components/InfoGrid/info-grid'


const SubPage = ({ data }) => {
  const {
    title,
    seoMetaTags,
    sections
  } = data.datoCmsSubpage


  return (
    <Layout
      seo={seoMetaTags.tags}
      template="single-project"
    >
      <SimpleHero title={title} />
      {sections.map((section, index) => (
        <InfoGrid
          key={`${section.title}-${index}`}
          title1={section.title}
          text1={false}
          title2={false}
          text2={section.text}
        />
      ))}

    </Layout>
  )
}

export default SubPage

export const query = graphql`
  query SubPageQuery($slug: String!) {
    datoCmsSubpage(slug: {eq: $slug}) {
      slug
      title
      seoMetaTags {
        tags
      }
      sections {
        title
        text
      }
    }
  }
`
