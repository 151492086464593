import styled from 'styled-components'
import { breakpoint, Halign } from '../../layout/mixins'
import { SliderButton } from '@typeform/embed-react'

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: ${({ theme: { headerheightMobile, gutterLarge } }) => `calc(${headerheightMobile} + ${gutterLarge})`};
  background: ${({ theme }) => theme.colorBlack};
  z-index: ${({ theme }) => theme.zLevel5};
  display: none;

  .menu-open & {
    display: block;
  }

  ${breakpoint.up('desktop')`
    .menu-open & {
      display: none;
    }
  `}
`

export const TypeFormButton = styled(SliderButton)`
  margin-top: ${({ theme }) => theme.gutterMedium};
  padding: ${({ theme }) => theme.gutterSmall};
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  border: ${({ theme }) => `${theme.borderSize} solid ${theme.textcolorSecondary}`};
  transition: all ${({ theme }) => theme.transitionRegular};
  color: ${({ theme }) => theme.textcolorSecondary};
  font-size: 25px;
    line-height: 39px;
  &:hover {
    background: ${({ theme }) => theme.textcolorPrimary};
    color: ${({ theme }) => theme.textcolorSecondary};
  }
`

export const StyledHalign = styled(Halign)`
    height: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.gutter};
  height: 100%;

  ${breakpoint.up('tablet')`
    padding-left: ${({ theme }) => theme.gutter};
  `}
`

export const NavList = styled.ul`
    margin-bottom: ${({ theme }) => theme.gutterMedium};
`

export const NavItem = styled.li`
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  font-size: 40px;
  line-height: 1.1em;
  margin-bottom: ${({ theme }) => theme.gutter};

  && a {
    color: ${({ theme }) => theme.textcolorSecondary};
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfoItem = styled.div`

  a {
    color: ${({ theme }) => theme.textcolorSecondary};
    font-size: 25px;
    line-height: 39px;
  }

  .header--light & a {
    color: ${({ theme }) => theme.textcolorPrimary};
  }
`