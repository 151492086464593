import React from 'react'
import {
  Wrapper,
  Inner,
  Content,
  Heading,
  Arrow,
  Filters,
  FilterLink,
  Filter
} from './styles'

const InsightListingHero = ({
  title,
  children,
  className,
  filters,
  activeFilter
}) => (
  <Wrapper
    className={className}
  >
    <Inner>
      <Arrow />
      <Content>
        <Heading dangerouslySetInnerHTML={{ __html: title }} />
        { filters.length > 1 && (
          <Filters>
            <Filter
              className={activeFilter === 'all' ? 'active' : ''}
              key="All"
            >
              <FilterLink to="/insights">All</FilterLink>
            </Filter>
            {filters.map(({ slug, title, id }) => (
              <Filter
                className={activeFilter === slug ? 'active' : ''}
                key={id}
              >
                <FilterLink
                  to={`/insights/${slug}`}
                >
                  {title}
                </FilterLink>
              </Filter>
            ))}
          </Filters>
        )}
        {children}
      </Content>
    </Inner>
  </Wrapper>
)

export default InsightListingHero