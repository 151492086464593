import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import InsightListingHero from '../../components/InsightListingHero/insight-listing-hero'
import InsightsGrid from '../../components/InsightsGrid/insights-grid'

const InsightsArea = ({ data, pageContext }) => {
  const {
    heroTitle,
    seoMetaTags,
    showFilters
  } = data.page

  
  const activeFilter = pageContext?.slug

  const { edges: tagEdges } = data.insightsTags

  const modifiedInsights = data.insights.edges.map((item) => ({ ...item.node }))

  const filters = tagEdges.map((item) => ({ ...item.node })).filter((item) => showFilters.includes(item.title))

  return (
    <Layout
      seo={seoMetaTags.tags}
      template="single-project"
    >
      <InsightListingHero
        title={heroTitle}
        filters={filters}
        activeFilter={activeFilter}
      />
      <InsightsGrid insights={modifiedInsights} />
    </Layout>
  )
}

export default InsightsArea

export const query = graphql`
  query InsightsAreaQuery($id: [String]) {
    page: datoCmsInsightsPage {
      heroTitle
      showFilters
      seoMetaTags {
        tags
      }    
    }
    insights: allDatoCmsInsight(filter: {tags: {elemMatch: {originalId: {in: $id }}}}, sort: {order: ASC, fields: meta___firstPublishedAt}) {
      edges {
        node {
          ...InsightCardFragment
        }
      }
    },
    insightsTags: allDatoCmsInsightsTag {
      edges {
        node {
          id: originalId,
          title,
          slug
        }
      }
    }
  }  
`