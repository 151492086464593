import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import InsightListingHero from '../../components/InsightListingHero/insight-listing-hero'
import InsightsGrid from '../../components/InsightsGrid/insights-grid'
import InsightCard from '../../components/InsightCard/insight-card'
import { Highlight } from './styles'

const InsightsPage = ({ data }) => {
  const {
    heroTitle,
    seoMetaTags,
    showFilters
  } = data.page


  const { edges: tagEdges } = data.insightsTags

  const showHighlight = data.insights.edges.length >= 1 && data.insights.edges.length !== 2

  const { edges: [{ node: highlight }, ...remainingInsights] } = data.insights
  const modifiedInsights = (showHighlight ? remainingInsights : data.insights.edges).map((item) => ({ ...item.node }))

  const filters = tagEdges.map((item) => ({ ...item.node })).filter((item) => showFilters.includes(item.title))

  return (
    <Layout
      seo={seoMetaTags.tags}
      template="single-project"
    >
      <InsightListingHero
        title={heroTitle}
        filters={filters}
        activeFilter="all"
      />
      { showHighlight && (
        <Highlight>
          <InsightCard
            insight={highlight}
            backgroundAnimation={false}
          />
        </Highlight>
      )}
      <InsightsGrid insights={modifiedInsights} />
    </Layout>
  )
}

export default InsightsPage

export const query = graphql`
  query InsightsPageQuery {
    page: datoCmsInsightsPage {
      heroTitle
      showFilters
      seoMetaTags {
        tags
      }    
    }
    insights: allDatoCmsInsight(sort: {order: ASC, fields: meta___firstPublishedAt}) {
      edges {
        node {
          ...InsightCardFragment
        }
      }
    },
    insightsTags: allDatoCmsInsightsTag {
      edges {
        node {
          id: originalId,
          title,
          slug
        }
      }
    }
  }  
`