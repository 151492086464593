import styled from 'styled-components'
import { breakpoint } from '../../../layout/mixins'
import Video from '../../Video'

export const Iframe = styled.iframe`
  width: ${({ theme }) => `calc(100vw - (2 * ${theme.gutter}))`};
  height: ${({ theme }) => `calc(100vw - (2 * ${theme.gutter}))`};
  margin: 0 auto;
  display: block;
  
  ${breakpoint.up('tablet')`
    width: 60vw;
    height: calc(60vw * 0.5625);
  `}
`

export const VideoLink = styled.div`
  cursor: pointer;
`

export const StyledVideo = styled(Video)`
  height: 0;
  padding-top: 82%; //by design

  video {
    position: absolute;
    top: 0;
    left: 0;
  }
`