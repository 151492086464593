import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import {
  Halign,
  breakpoint,
  Heading1,
  truncateOverflow
} from '../../layout/mixins'
import ArrowIcon from '../../layout/icons/long-arrow.svg'
import Video from '../Video'

export const Wrapper = styled.section`
  position: relative;
  min-height: 28rem;
  height: 10rem;

  ${({ backgroundColor }) => backgroundColor && `
    background-color: ${backgroundColor}; 
  `}
`

export const ImageWrapper = styled(BackgroundImage)`
  min-height: 28rem;
  height: 10rem;
`

export const StyledVideo = styled(Video)`
    ${breakpoint.up('max')`
      overflow: hidden;

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
      }
    `}
`

export const Inner = styled(Halign)`
  position: absolute;
  top: ${({ theme }) => theme.headerheight};
  height: calc(100% - ${({ theme }) => theme.headerheight});
  display: flex;
  align-items: flex-end;
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    padding-left: ${({ theme }) => theme.gutter};
  `}
`


export const HeadingGroup = styled.div``

export const Heading = styled(Heading1)`
  color: ${({ theme }) => theme.textcolorSecondary};

`


export const Arrow = styled(ArrowIcon)`
  transform-origin: bottom center;
  position: absolute;
  left: ${({ theme }) => `calc(${theme.pagepaddingDesktop} - 0.45rem)`};
  top: -${({ theme }) => theme.headerheight};
  height: ${({ theme }) => `calc(100% + ${theme.headerheight} - ${theme.gutter})`};
  fill: ${({ theme }) => theme.colorWhite};
  width: 16px;
  display: none;

  ${breakpoint.up('tablet')`
    display: block;
  `}
`
