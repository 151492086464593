import styled, { css } from 'styled-components'
import styledBreakpoint from '@humblebee/styled-components-breakpoint'
import theme from './theme'

export const breakpoint = styledBreakpoint({
  phone: theme.breakpoints.phone,
  tablet: theme.breakpoints.tablet,
  desktop: theme.breakpoints.desktop,
  large: theme.breakpoints.large,
  max: theme.breakpoints.max,
  xLarge: theme.breakpoints.xLarge
})

export const hyphens = css`
  hyphens: auto;
  @supports not (hyphens: auto) {
    word-break: break-all;
  }

  ${breakpoint.up('desktop')`
    hyphens: none;
    word-break: normal;
  `}
`

export const Halign = styled.div`
  margin: 0 auto;
  width: 100%;
  padding-left: ${theme.pagepaddingMobile};
  padding-right: ${theme.pagepaddingMobile};

  ${breakpoint.up('tablet')`
    padding-left: ${theme.pagepaddingDesktop};
    padding-right: ${theme.pagepaddingDesktop};
  `}
`


export const BlockPaddingTopStyles = css`
  padding-top: ${theme.blockpaddingMobile};

  ${breakpoint.up('desktop')`
    padding-top: ${theme.blockpadding};
  `}
`

export const BlockPaddingTop = styled.section`
  ${BlockPaddingTopStyles}
`

export const BlockPaddingBottomStyles = css`
  padding-bottom: ${theme.blockpaddingMobile};

  ${breakpoint.up('desktop')`
    padding-bottom: ${theme.blockpadding};
  `}
`

export const BlockPaddingBottom = styled.section`
  ${BlockPaddingBottomStyles}
`

export const BlockPaddingBothStyles = css`
  padding-top: ${theme.blockpaddingMobile};
  padding-bottom: ${theme.blockpaddingMobile};

  ${breakpoint.up('desktop')`
    padding-top: ${theme.blockpadding};
    padding-bottom: ${theme.blockpadding};
  `}
`

export const BlockPaddingBoth = styled.section`
  ${BlockPaddingBothStyles}
`


export const InsightHalign = styled(Halign)`
  padding-top: ${theme.gutterMedium};

  ${breakpoint.up('desktop')`
    padding-top: ${theme.gutterLarge};
  `}

  ${breakpoint.up('max')`
    max-width: 67%;
  `}
`

export const Heading1Styles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 2.714rem;
  line-height: 1em;
  font-weight: normal;
  margin: 0;

  ${breakpoint.up('desktop')`
    font-size: 4.44rem;
  `}
`

export const Heading1 = styled.h1`
  ${Heading1Styles}
`

export const Heading2Styles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 2.14rem;
  line-height: 1.045em;
  font-weight: normal;
  margin: 0;

  ${breakpoint.up('desktop')`
    font-size: 2.44rem;
  `}
`

export const Heading2 = styled.h2`
  ${Heading2Styles}
`

export const Heading3Styles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 2.14rem;
  line-height: 1em;
  font-weight: 400;
  margin: 0;
  text-transform: none;

  ${breakpoint.up('desktop')`
    font-size: 2.5rem;
  `}
`

export const Heading3 = styled.h3`
  ${Heading3Styles}
`

export const Heading4Styles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 1.56rem;
  line-height: 1.27em;
  font-weight: 400;
  margin: 0;
  text-decoration: underline;
`

export const Heading4 = styled.h3`
  ${Heading4Styles}
`

export const CaseHeading1Styles = css`
  font-family: ${theme.fontfamilySecondary};
  font-display: swap;
  font-size: 1.9rem;
  line-height: 1.1em;
  font-weight: normal;
  margin: 0;

  ${breakpoint.up('phone')`
    font-size: 2.5rem;
  `}

  ${breakpoint.up('desktop')`
    font-size: 2.78rem;
  `}
`

export const CaseHeading2Styles = css`
  font-family: ${theme.fontfamilySecondary};
  font-display: swap;
  font-size: 1.3rem;
  line-height: 1.167em;
  font-weight: normal;
  margin: 0;

  ${breakpoint.up('phone')`
    font-size: 1.625rem;
  `}

  ${breakpoint.up('desktop')`
    font-size: 1.67rem;
  `}
`

export const ParagraphStyles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.4em;
  max-width: ${theme.textMaxWidth};
  margin-top: 0;
  
  & + p {
    margin-top: 1em;
  }

  ${breakpoint.up('phone')`
    font-size: 1.33em;
  `}
`

export const Paragraph = styled.p`
  ${ParagraphStyles}
`

export const ParagraphLargeStyles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 1.58rem;
  font-weight: 400;
  line-height: 1.25em;
  max-width: ${theme.textMaxWidth};
`

export const ParagraphLarge = styled.p`
  ${ParagraphLargeStyles}
`

export const TextSmallStyles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.33em;
  max-width: ${theme.textMaxWidth};
`

export const TextSmall = styled.p`
  ${TextSmallStyles}
`

export const truncateOverflow = (lines = 3, onlyMobile = false) => (onlyMobile ? css`
    ${breakpoint.down('desktop')`
      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;  
      overflow: hidden;
    `}
  ` : css`
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;  
    overflow: hidden;    
  `)