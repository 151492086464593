import styled from 'styled-components'
import { Heading2, ParagraphLargeStyles, breakpoint } from '../../layout/mixins'
import Divider from '../../components/Divider/divider'
import FeaturedProjectsGrid from '../../components/FeaturedProjectsGrid/featured-projects-grid'
import InfoGrid from '../../components/InfoGrid/info-grid'
import {
  Text,
  Title
} from '../../components/InfoGrid/styles'
import {
  FooterBackground
} from '../../components/FeaturedProject/styles'

export const StyledH2 = styled(Heading2)`
  padding: ${({ theme }) => `${theme.blockpadding} ${theme.gutterSmall} ${theme.gutterSmall} ${theme.gutterSmall}`};
`

export const StyledDivider = styled(Divider)`
  padding: ${({ theme }) => theme.gutterSmall};
`

export const StyledFeaturedProjectsGrid = styled(FeaturedProjectsGrid)`
 padding-top: 0;

 ${FooterBackground} {
   background: white;
 }
`

export const StyledInfoGrid = styled(InfoGrid)`
  ${Text}, ${Title} {
    ${breakpoint.up('desktop')`
      ${ParagraphLargeStyles}   
    `}
  }
`
