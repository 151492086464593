import React from 'react'
import { graphql } from 'gatsby'
import {
  Wrapper,
  Text
} from './styles'

const TextContent = ({ data }) => {
  const { text } = data;
  return (
    <Wrapper>
      <Text>{text}</Text>
    </Wrapper>
  )
}

export default TextContent


export const TEXT_CONTENT_BLOCK_FRAGMENT = graphql`
  fragment TextContentBlockFragment on DatoCmsTextContent {
    id
    text
  }
`