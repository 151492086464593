import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from 'styled-components'

const Button = styled.button`
  font-size: 1.375rem;
  text-decoration: underline;
  font-family: ${({ theme }) => theme.fontfamilyPrimary};
  line-height: ${({ theme }) => theme.lineheightRegular};
`

const CopyLink = ({
  toCopy, text, onCopiedText, className
}) => {
  const [copied, setCopied] = useState(false)

  const onCopy = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <>
      <CopyToClipboard
        onCopy={onCopy}
        text={`${toCopy}`}
      >
        <Button className={className}>{copied ? onCopiedText : text}</Button>
      </CopyToClipboard>

    </>
  )
}

export default CopyLink

CopyLink.propTypes = {
  toCopy: PropTypes.string.isRequired,
  text: PropTypes.string,
  onCopiedText: PropTypes.string,
  className: PropTypes.string
}

CopyLink.defaultProps = {
  text: 'Copy link',
  onCopiedText: 'Copied!',
  className: null
}