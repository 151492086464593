import React from 'react'
import Layout from '../../components/Layout'
import {
  StyledSimpleHero,
  StyledHeading,
  StyledArrowIcon
} from './styles'
import CustomLink from '../../components/CustomLink'

const NoPageFound = () => {
  const title = `
    Sorry,
    <br>
    page not found.
  `

  return (
    <Layout
      template="404"
    >
      <StyledSimpleHero title={title}>
        <CustomLink to="/">
          <StyledHeading>
            Is it me you're
            <br />
            looking for. <StyledArrowIcon />
          </StyledHeading>
        </CustomLink>
      </StyledSimpleHero>
    </Layout>
  )
}

export default NoPageFound