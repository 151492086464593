import React from 'react'
import { graphql } from 'gatsby'
import {
  StyledDivider,
  StyledH2,
  StyledInfoGrid,
  StyledFeaturedProjectsGrid
} from './styles'
import Layout from '../../components/Layout'
import SingleProjectHero from '../../components/SingleProjectHero/single-project-hero'
import SingleCaseGrid from '../../components/SingleCaseGrid/single-case-grid'
import Divider from '../../components/Divider/divider'
import Helmet from 'react-helmet'

const SingleProject = ({ data }) => {
  const {
    id,
    slug,
    title,
    serviceFields,
    website,
    client,
    heroBackgroundMedia,
    heroBackgroundType,
    heroBackgroundColor,
    heroAnnouncement,
    about,
    grid,
    seoMetaTags,
    similarProjects
  } = data.datoCmsProject

  const { edges: projectEdges } = data.allDatoCmsProject
  const serviceAreas = serviceFields.map((item) => item.serviceAreaName)

  let projectIds = []
  const modifiedProjects = projectEdges.map((item) => {
    projectIds.push(item.node.id)
    return { ...item.node }
  })

  const getProjectNumber = () => {
    return projectIds.findIndex((item) => item == id) + 1
  }

  const clientAndWebsite = () => {
    if (website) {
      const url = new URL(website)

      return `
        <div className="rte">
          <div>${client}</div>
          <a rel="noopener noreferrer" target="_blank" href="${website}">${url.hostname}</a>
        </div>
      `
    }
    if(client) {
      return `
      <div className="rte">
        <div>${client}</div>
      </div>
    `
    }

    return ''
  }

  return (
    <Layout
      seo={seoMetaTags.tags}
      template="single-project"
    >
      <Helmet>
        <link rel="dns-prefetch" href="https://cdnjs.cloudflare.com" crossOrigin="true" />
        <link rel="preload" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" as="style" crossOrigin />
        <link rel="preload" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" as="style" crossOrigin />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" integrity="sha256-jySGIHdxeqZZvJ9SHgPNjbsBP8roij7/WjgkoGTJICk=" crossOrigin="anonymous" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" integrity="sha256-WmhCJ8Hu9ZnPRdh14PkGpz4PskespJwN5wwaFOfvgY8=" crossOrigin="anonymous" />
      </Helmet>
      <SingleProjectHero
        title={title}
        announcement={heroAnnouncement}
        backgroundMedia={heroBackgroundMedia}
        backgroundType={heroBackgroundType}
        backgroundColor={heroBackgroundColor?.hex}
        allProjects={modifiedProjects}
        projectNumber={getProjectNumber()}
        slug={slug}
      />
      <StyledInfoGrid
        title1="About the project"
        text1=""
        title2=""
        text2={about}
      />
      <StyledInfoGrid
        title1="Balance between"
        list1={serviceAreas}
        title2="Client"
        text2={clientAndWebsite()}
      />
      <SingleCaseGrid items={grid} />
      {similarProjects.length > 0 && (
        <>
          <StyledH2>Similar Projects</StyledH2>
          <StyledFeaturedProjectsGrid
            projects={similarProjects}
            projectIds={projectIds}
          />
        </>
      )}
      </Layout>
  )
}

export default SingleProject

export const query = graphql`
  query SingleProjectQuery($slug: String!) {
    datoCmsProject(slug: {eq: $slug}) {
      id
      slug
      title
      serviceFields {
        serviceAreaName
      }
      website
      client
      heroBackgroundType
      heroBackgroundColor {
        hex
      }
      heroBackgroundMedia {
        fluid(maxWidth: 2560, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        url
      }
      heroAnnouncement
      seoMetaTags {
        tags
      }
      about
      grid {
        __typename
        ...TextContentBlockFragment
        ...VideoBlockFragment
        ...MoodImageBlockFragment
        ...CaseImageBlockFragment
      }
      similarProjects {
        ...FeaturedProjectFragment
      }
    }
    allDatoCmsProject(sort: { fields: [position], order: DESC }) {
      edges {
        node {
          ...FeaturedProjectFragment
        }
      }
    }
  }
`
