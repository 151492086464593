import React from 'react'
import { graphql } from 'gatsby'
import { getIframeSrc } from '../../../utils/helpers'
import {
  Iframe,
  VideoLink,
  StyledVideo
} from './styles'
import Toggle from '../../Toggle'
import Modal from '../../Modal'

const Video = ({ data }) => {
  const { previewVideo, providerVideo } = data;

  return (
    <Toggle>
      {(on, toggle) => (
        <>
          <VideoLink onClick={toggle}>
            <StyledVideo
              url={previewVideo.url}
              caption="Hitta hit"
              autoPlay={true}
              showPlayButton={true}
              onPlayButtonClick={toggle}
            />
          </VideoLink>
          {on && (
            <Modal toggle={toggle}>
              <Iframe
                src={getIframeSrc(providerVideo.provider, providerVideo.providerUid)}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                autoPlay={true}
              />
            </Modal>
          )}
        </>
      )}
    </Toggle>
  )
}

export default Video


export const VIDEO_BLOCK_FRAGMENT = graphql`
  fragment VideoBlockFragment on DatoCmsVideo {
    id
    previewVideo {
      url
    }
    providerVideo {
      providerUid
      provider
    }
  }
`