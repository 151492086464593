import styled from 'styled-components'
import { Halign, breakpoint, Heading1 } from '../../layout/mixins'
import ArrowIcon from '../../layout/icons/long-arrow.svg'

export const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.headerheight};
  min-height: 400px;
  background: black;
  height: 10rem;
`

export const Inner = styled(Halign)`
  position: relative;
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    padding-left: ${({ theme }) => theme.gutter};
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: row;
  `}
`

export const Heading = styled(Heading1)`
  color: ${({ theme }) => theme.textcolorSecondary};
`

export const Arrow = styled(ArrowIcon)`
  position: absolute;
  left: ${({ theme }) => `calc(${theme.pagepaddingDesktop} - 0.45rem)`};
  top: -${({ theme }) => theme.headerheight};
  height: ${({ theme }) => `calc(100% + ${theme.headerheight} - ${theme.gutter})`};
  fill: ${({ theme }) => theme.colorWhite};
  display: none;

  ${breakpoint.up('tablet')`
    display: block;
  `}
`