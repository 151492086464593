import React from 'react'
import {
  Wrapper,
  Grid,
  GridItem
} from './styles'
import BlockRenderer from '../BlockRenderer/block-renderer'

const SingleCaseGrid = ({ items }) => {

  // Add case image gallery case image block for light box gallery
  const caseImgs = items.filter((block) => block.__typename === 'DatoCmsCaseImage')
  
  const modifiedItems = items.map((block) => {
    if (block.__typename === 'DatoCmsCaseImage') {
      return {
        ...block,
        caseImgs
      }
    } else {
      return block
    }
  })

  return (
    <Wrapper>
      <Grid>
        {modifiedItems.map(block => {
          return (
            <GridItem key={block.id}>
              <BlockRenderer key={block.id} block={block} />
            </GridItem>
          )
        })}
      </Grid>
    </Wrapper>
  )
}

export default SingleCaseGrid