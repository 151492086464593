import styled from 'styled-components'
import {
  Halign,
  breakpoint,
  Heading1,
  ParagraphStyles
} from '../../layout/mixins'
import ArrowIcon from '../../layout/icons/long-arrow.svg'
import CustomLink from '../CustomLink'

export const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.headerheight};
  min-height: 400px;
  background: black;
  height: 10rem;
`

export const Inner = styled(Halign)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-end;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    padding-left: ${({ theme }) => theme.gutter};
  `}

  ${breakpoint.up('desktop')`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `}
`

export const Heading = styled(Heading1)`
  color: ${({ theme }) => theme.textcolorSecondary};
  margin-bottom: ${({ theme }) => theme.gutterLarge};

  ${breakpoint.up('desktop')`
    margin-bottom: 0;
  `}
`

export const Arrow = styled(ArrowIcon)`
  position: absolute;
  left: ${({ theme }) => `calc(${theme.pagepaddingDesktop} - 0.45rem)`};
  top: -${({ theme }) => theme.headerheight};
  height: ${({ theme }) => `calc(100% + ${theme.headerheight} - ${theme.gutter})`};
  fill: ${({ theme }) => theme.colorWhite};
  display: none;

  ${breakpoint.up('tablet')`
    display: block;
  `}
`

export const Filters = styled.ul`
  
`

export const Filter = styled.li`
  ${ParagraphStyles} 
  margin-bottom: 0.5rem;
  position: relative;
  color: ${({ theme }) => theme.textcolorSecondary};

  &:last-child {
    margin-bottom: 0;
  }
`

export const FilterLink = styled(CustomLink)`
  position: relative;
  color: ${({ theme }) => theme.textcolorSecondary};
  
  .active &:before {
    content: '';
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 151 16'><path d='M150.707 8.707a1 1 0 000-1.414L144.343.929a1 1 0 10-1.414 1.414L148.586 8l-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM0 9h150V7H0v2z' stroke='none' fill='white'/></svg>");
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    left: calc(100% + ${({ theme }) => theme.gutterMedium});
    width: 8.39rem;
    height: 0.89rem;
    pointer-events: none;


    ${breakpoint.up('desktop')`
      transform: translateY(-50%);
      left: unset;
      right: calc(100% + ${({ theme }) => theme.gutter});
      pointer-events: none;
    `}
  }

  .active &:after {
    content: "Filter";
    position: absolute;
    right: calc(100% + ${({ theme }) => theme.gutter} + 9rem);
  }
`