import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Wrapper,
  Inner,
  Grid,
  GridItem,
  StyledDivider,
  StyledLogo
} from './styles'

const Footer = () => {
  const { content } = useStaticQuery(query).datoCmsFooter

  return (
    <Wrapper>
      <StyledDivider
        top={false}
        bottom={false}
      />
      <Inner>
        <Grid>
          {content.map((item) => {
            return (
              <GridItem key={`grid-item-${item.id}`} dangerouslySetInnerHTML={{ __html: item.text }} />
            )
          })}
          <GridItem>
            <StyledLogo />
          </GridItem>
        </Grid>
      </Inner>
    </Wrapper>
  )
}

export default Footer

export const query = graphql`
  query FooterQuery {
    datoCmsFooter {
      content {
        id
        text
      }
    }    
  }
`
