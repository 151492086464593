import { trimUnits } from '../utils/helpers'

class Theme {
  constructor() {
    this.colorAccent = '#FFFF00'
    this.colorBlack = '#000'
    this.colorWhite = '#FFF'
    this.colorGrey = '#F0F0F0'
    this.colorGreylight = '#FAFAFA'
    this.colorDanger = 'red'
    this.colorSould = '#FFF2F2'

    this.textcolorPrimary = '#000'
    this.textcolorSecondary = '#fff'

    this.colorHr = '#CCCCCC'

    this.gridUnit = '5.5rem'
    this.gutter = '1.5rem'
    this.gutterSmall = `${trimUnits(this.gutter) / 2}rem`
    this.gutterMedium = `${trimUnits(this.gutter) * 1.5}rem`
    this.gutterLarge = `${trimUnits(this.gutter) * 2}rem`
    this.gutterXLarge = `${trimUnits(this.gutter) * 3}rem`
    this.gutterXXLarge = `${trimUnits(this.gutter) * 4}rem`

    this.maxWidth = '1440px'
    this.maxTextWidth = '40rem'

    this.headerheightMobile = this.gutterLarge
    this.headerheight = '4rem'

    this.pagepaddingDesktop = '6.5rem'
    this.pagepaddingMobile = this.gutter

    this.blockpaddingMobile = this.gutterXLarge
    this.blockpadding = `${trimUnits(this.gutterLarge) * 1.5}rem`

    this.borderSize = '2px'

    this.fontfamilyPrimary = 'AllianceNo1-Regular, sans-serif'
    this.fontfamilySecondary = 'AllianceNo1-Regular, sans-serif'

    this.fontsizeMobile = '16px'
    this.fontsizeResponsive = 'calc(12px + 0.416vw)'
    this.fontsizeRoot = '18px'
    this.fontsizeSmall = '0.8em'

    this.fontwidthMax = '55rem'

    this.lineheightRegular = '1.3em'
    this.lineheightSmall = '1.1em'

    this.buttonRadius = '40px'
    this.contentRadius = '8px'

    this.boxShadow = '0px 6px 10px rgba(0, 0, 0, 0.1)'

    this.zLevel1 = '1'
    this.zLevel2 = '2'
    this.zLevel3 = '3'
    this.zLevel4 = '4'
    this.zLevel5 = '5'
    this.zLevel6 = '6'

    this.animatedArrowWidth = '119px'

    this.transitionRegular = '0.4s ease-out'

    this.breakpoints = {
      min: 0,
      phone: 668,
      tablet: 768,
      desktop: 1024,
      large: 1280,
      max: trimUnits(this.maxWidth),
      xLarge: 1920
    }
  }
}

const theme = new Theme()

export default theme