import styled, { css } from 'styled-components'
import BackgroundImage from 'gatsby-image'
import {
  Heading4Styles,
  breakpoint,
  truncateOverflow,
} from '../../layout/mixins'
import ArrowIcon from '../../layout/icons/horizontal-short-arrow.svg'
import CustomLink from '../CustomLink'
import Video from '../Video'
import siteTheme from '../../layout/theme'

export const WrapperWithoutHover = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

export const WrapperWithHover = styled(WrapperWithoutHover)``

const HoverEffect = css`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${siteTheme.colorAccent};
    opacity: 0;
    z-index: ${siteTheme.zLevel3};
    transition: opacity ${siteTheme.transitionRegular};
  }

  ${WrapperWithHover}:hover &:before {
    opacity: 0.8;
  }
`

export const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${siteTheme.zLevel2};

  ${HoverEffect}

`

export const StyledVideo = styled(Video)`
    position: absolute;
    z-index: ${siteTheme.zLevel2};


    ${breakpoint.up('tablet')`
      ${HoverEffect}
    `}

    ${breakpoint.up('max')`
      overflow: hidden;

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    `}
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Image = styled(CustomLink)`
  position: relative;
  display: block;
  flex: 1;
  height: 100%;

`

export const Header = styled.div`
  position: relative;
  height: 0;
  padding-top: 56.25%;
  width: 100%;
  z-index: ${siteTheme.zLevel4};
`

export const HeaderContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${siteTheme.gutter};
  opacity: 0;
  transition: opacity ${siteTheme.transitionRegular};

  ${WrapperWithHover}:hover & {
    ${breakpoint.up('tablet')`
      opacity: 1;
    `}
  }
`

export const Title = styled.h2`
  ${Heading4Styles}
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  transition: color ${siteTheme.transitionRegular};
  ${truncateOverflow(2)}

  ${breakpoint.up('tablet')`
    font-size: ${({ theme }) => theme.textSize};
  `}

  ${WrapperWithHover}:hover & {
    color: ${({ theme }) => theme.hoverTextColor};
  }

`

export const Footer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 2;
`


export const FooterContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.gutterSmall} 0`};
  transition: background ${siteTheme.transitionRegular}, color ${siteTheme.transitionRegular};
  z-index: ${siteTheme.zLevel4};
  color: ${({ theme }) => theme.textColor};

  ${({ padding, theme }) => padding && `
    padding: ${theme.gutterSmall};
  `}

  ${breakpoint.up('tablet')`
    padding: ${({ theme }) => `${theme.gutterSmall} ${theme.gutter}`};
  `}


  ${WrapperWithHover}:hover & {
    ${breakpoint.up('tablet')`
      color: ${({ theme }) => theme.hoverTextColor};
    `}
  }
`

export const FooterBackground = styled.div`

  ${({ backgroundAnimation }) => !backgroundAnimation && `
    display: none;
  `}

  position: absolute;
  bottom: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: 100%;
  transition: transform ${siteTheme.transitionRegular};
  transform-origin: bottom center;
  background: ${siteTheme.colorWhite};
  z-index: ${siteTheme.zLevel2};

  ${WrapperWithHover}:hover & {
    ${breakpoint.up('tablet')`
      transform: scale3d(1, 0, 0); 
    `}
  }
`


export const FooterGroup = styled.div`
  ${breakpoint.up('tablet')`
    padding-right: ${siteTheme.gutterMedium};
  `}

  &:last-child {
    display: flex;
    align-items: center;
    padding-right: 0;
  }
`

export const Arrow = styled(ArrowIcon)`
  fill: ${({ theme }) => theme.arrowColor};
  transition: fill ${siteTheme.transitionRegular};
  
  .line {
    transition: all ${siteTheme.transitionRegular};
    transform-origin: -100% 50%;
    fill:none;
    stroke: ${({ theme }) => theme.arrowColor};
    stroke-width:2;
    stroke-dashoffset: 0;
    stroke-dasharray: 55;
  }

  ${WrapperWithHover}:hover &,
  ${WrapperWithoutHover}:hover & {
    ${breakpoint.up('tablet')`
      fill: ${({ theme }) => theme.hoverArrowColor};
    
      .line {
        stroke: ${({ theme }) => theme.hoverArrowColor};
        stroke-dasharray: 100;
      }
    `}
  }
`

export const themes = {
  dark: {
    textSize: '1.56rem',
    textColor: siteTheme.textcolorPrimary,
    hoverTextColor: siteTheme.textcolorPrimary,
    arrowColor: siteTheme.textcolorPrimary,
    hoverArrowColor: siteTheme.textcolorPrimary
  },
  light: {
    textSize: '2.14rem',
    textColor: siteTheme.textcolorSecondary,
    hoverTextColor: siteTheme.textcolorPrimary,
    arrowColor: siteTheme.colorAccent,
    hoverArrowColor: siteTheme.textcolorPrimary
  }
}