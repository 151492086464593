import styled from 'styled-components'
import SimpleHero from '../../components/SimpleHero/simple-hero'
import {
  Heading1,
  breakpoint
} from '../../layout/mixins'
import {
  Heading
} from '../../components/SimpleHero/styles'
import ArrowIcon from '../../layout/icons/horizontal-long-arrow.svg'

export const StyledSimpleHero = styled(SimpleHero)`
  min-height: 80vh;

  ${Heading} {
    margin-top: ${({ theme }) => theme.gutterMedium};
    margin-bottom: ${({ theme }) => theme.gutterMedium};
  }
`

export const StyledHeading = styled(Heading1)`
  color: ${({ theme }) => theme.textcolorSecondary};

  ${breakpoint.up('tablet')`
    padding-left: ${({ theme }) => theme.gutter};
    position: absolute;
    right: 0;
    top: ${({ theme }) => `calc(50% - ${theme.headerheight})`};
    transform: translateY(-50%);
    margin-bottom: 0;
  `}
`

export const StyledArrowIcon = styled(ArrowIcon)`
  fill: ${({ theme }) => theme.textcolorSecondary};
  vertical-align: middle;
`