import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

const uuidv1 = require('uuid/v1')

const SEO = ({ seoTags, bodyClass }) => {
  const faviconTags = useStaticQuery(metaQuery)

  return (
    <>
      <Helmet
        title="Sould"
        bodyAttributes={{
          class: bodyClass
        }}
      >
        {/* SEO meta */}
        {seoTags.map((tag) => {
          if (tag.tagName === 'title') {
            return <title key={uuidv1()}>{tag.content}</title>
          }
          if (tag.tagName === 'meta') {
            return <meta key={uuidv1()} {...tag.attributes} />
          }
        })}

        {/* Favicon meta */}
        {faviconTags.datoCmsFaviconMetaTags.tags.map((tag) => {
          if (tag.tagName === 'link') {
            return <link key={uuidv1()} {...tag.attributes} />
          }

          if (tag.tagName === 'meta') {
            return <meta key={uuidv1()} {...tag.attributes} />
          }
        })}
        <html lang="en" />
      </Helmet>
    </>
  )
}

export default SEO

SEO.propTypes = {
  seoTags: PropTypes.array,
  bodyClass: PropTypes.string
}

SEO.defaultProps = {
  seoTags: [],
  bodyClass: ''
}

const metaQuery = graphql`
  query SEO {
    datoCmsFaviconMetaTags {
      tags
    }
  }
`