import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'

export const Wrapper = styled.section`
  padding-top: ${({ theme }) => theme.blockpadding};
  padding-left: ${({ theme }) => theme.gutterSmall};
  padding-right: ${({ theme }) => theme.gutterSmall};
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: ${({ theme }) => `${theme.gutterMedium} ${theme.gutterSmall}`};

  ${breakpoint.up('tablet')`
    grid-template-columns: 1fr 1fr;
  `}
`

export const GridItem = styled.div``