import React from 'react'
import { graphql } from 'gatsby'
import {
  PRE,
  Background
} from './styles'
import { InsightHalign } from '../../../layout/mixins'

const Code = ({ data }) => (
  <>
    <InsightHalign>
      <Background>
        <PRE dangerouslySetInnerHTML={{ __html: data?.content }} />
      </Background>
    </InsightHalign>
  </>
)

export default Code


export const CODE_FRAGMENT = graphql`
  fragment DatoCmsCodeFragment on DatoCmsCode {
    id
    content
  }
`