import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Toggle from '../../Toggle'
import Modal from '../../Modal'
import Slider from '../../Slider'
import {
  Content,
  StyledImg,
  ImageContainer,
  Image,
  SliderImg,
  ImgWrapper,
  Enlarge
} from './styles'
import { isSSR } from '../../../utils/helpers'
import theme from '../../../layout/theme'

const CaseImage = ({ data }) => {
  const { id, image, caseImgs } = data
  const [enlargable, setEnlargable] = useState(true)

  const checkMedia = (event) => {
    if (event.matches && image.width > image.height) {
      setEnlargable(false)
    } else {
      setEnlargable(true)
    }
  }
  // Run only on client side
  if (!isSSR) {
    const match = window.matchMedia(`(max-width: ${theme.breakpoints.phone}px)`)

    useEffect(() => {
      match.addListener(checkMedia)
      checkMedia(match)
      return () => {
        match.removeListener(checkMedia)
      }
    }, [])
  }

  return (
    <>
      <Toggle>
        {(on, toggle) => (
          <>
            <ImgWrapper onClick={() => (enlargable ? toggle() : false)}>
              <StyledImg fluid={image.fluid} />
              <Enlarge visible={enlargable ? 1 : 0} />
            </ImgWrapper>
            {on && (
            <Modal toggle={toggle} modifier="modal--light">
              <Content
                width={caseImgs[0].image.width}
                height={caseImgs[0].image.height}
                onClick={(e) => {
                  if (e.target.classList.contains('slick-arrow')) return
                  if (e.target.closest('.slick-arrow')) return
                  toggle()
                }}
              >
                <Slider
                  initialSlide={caseImgs.findIndex((item) => item.id === id)}
                >
                  {caseImgs.map((item) => (
                    <div>
                      <ImageContainer>
                        <Image
                          src={item.image.url}
                          alt=""
                          imageWidth={item.image.width}
                          imageHeight={item.image.height}
                        />
                      </ImageContainer>
                    </div>
                  ))}
                </Slider>
              </Content>
            </Modal>
            )}
          </>
        )}
      </Toggle>
    </>
  )
}

export default CaseImage


export const CASE_IMAGE_BLOCK_FRAGMENT = graphql`
  fragment CaseImageBlockFragment on DatoCmsCaseImage {
    id
    image {
      fluid(maxWidth: 1280, imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
      width
      height
      url
    } 
  }
`

{ /* <SliderImg
  fluid={caseImgs[0].image.fluid}
  width={caseImgs[0].image.width}
  height={caseImgs[0].image.height}
  imgStyle={{ height: caseImgs[0].image.height, width: caseImgs[0].image.width }}
/> */ }