import React from 'react'
import {
  Wrapper,
  Inner,
  Content,
  Heading,
  Arrow
} from './styles'

const SimpleHero = ({ title, children, className }) => {
  
  return (
    <Wrapper
      className={className}
    >
      <Inner>
        <Arrow />
        <Content>
          <Heading dangerouslySetInnerHTML={{ __html: title }} />
          {children}
        </Content>
      </Inner>
    </Wrapper>
  )
}

export default SimpleHero