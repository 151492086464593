import styled from 'styled-components'
import CustomLink from '../../components/CustomLink'
import {
  Halign,
  BlockPaddingTopStyles,
  Heading2Styles,
  breakpoint
} from '../../layout/mixins'
import ContactGrid from '../../components/ContactGrid/contact-grid'
import { Grid } from '../../components/ContactGrid/styles'

export const ContactLinkAlign = styled(Halign)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${BlockPaddingTopStyles}
`

export const ContactLink = styled(CustomLink)`
  display: flex;
  align-items: center;
  ${Heading2Styles}
`

export const StyledContactGrid = styled(ContactGrid)`

  ${Grid} {
    ${breakpoint.up('desktop')`
      grid-template-columns: 1fr 1fr;
    `}

    ${breakpoint.up('large')`
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `}
  }
`