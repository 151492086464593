import styled from 'styled-components'
import Img from 'gatsby-image'
import {
  Halign,
  BlockPaddingTop,
  TextSmallStyles,
  breakpoint
} from '../../layout/mixins'

export const Wrapper = styled(BlockPaddingTop)``

export const Inner = styled(Halign)``

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: ${({ theme }) => `${theme.gutterMedium} ${theme.gutterSmall}`};

  ${breakpoint.up('desktop')`
    grid-template-columns: 1fr 1fr 1fr;
  `}

  ${breakpoint.up('large')`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`

export const GridItem = styled.div``

export const Image = styled(Img)`
  margin-bottom: ${({ theme }) => theme.gutterSmall};
`

export const InfoItem = styled.div`
  ${TextSmallStyles}
`

export const Name = styled(InfoItem)`
  text-decoration: underline;

  ${breakpoint.up('phone')`
    text-decoration: initial;
  `}
`

export const WorkTitle = styled(InfoItem)`
  margin: 0.3rem 0;

  ${breakpoint.up('phone')`
    margin: 0;
  `}
`

export const TitleStatus = styled(InfoItem)``

export const Phone = styled(InfoItem)`
  display: block;
`

export const Email = styled(InfoItem)`
  display: block;
`