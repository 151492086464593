import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  breakpoint, InsightHalign, BlockPaddingTop, Heading2
} from '../../layout/mixins'
import InsightsGrid from '../../components/InsightsGrid/insights-grid'

export const Wrapper = styled(BlockPaddingTop)`
`

export const Footer = styled(InsightHalign, BlockPaddingTop)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
`

export const StyledLink = styled(Link)`
  font-size: 1.375rem;
  text-decoration: underline;
  margin-left: ${({ theme }) => theme.gutterSmall};
`
export const StyledH2 = styled(Heading2)`
  padding: ${({ theme }) => `${theme.blockpadding} ${theme.gutterSmall} ${theme.gutterSmall} ${theme.gutterSmall}`};
`

export const StyledInsightsGrid = styled(InsightsGrid)`
 padding-top: 0;
`