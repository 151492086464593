import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import StartHero from '../../components/StartHero/start-hero'
import StartGrid from '../../components/StartGrid/start-grid'
import AnimatedArrow from '../../components/AnimatedArrow/animated-arrow'

import {
  ContactLink,
  ContactLinkAlign,
  StyledContactGrid
} from './styles'

const Home = ({ data }) => {

  const {
    heroTitle,
    seoMetaTags,
    heroBackgroundMedia,
    heroBackgroundType,
    heroBackgroundColor,
    contacts,
    contactsLink,
    heroVideoPoster
  } = data?.datoCmsStartPage || {}

  return (
    <Layout
      seo={seoMetaTags.tags}
      template="start"
    >
      <StartHero
        title={heroTitle}
        backgroundMedia={heroBackgroundMedia}
        backgroundType={heroBackgroundType}
        backgroundColor={heroBackgroundColor?.hex}
        backgroundPoster={heroVideoPoster?.fixed?.src}

      />
      <StartGrid/>
      <StyledContactGrid items={contacts} />
      <ContactLinkAlign as="div">
        <ContactLink to={contactsLink}>
          Meet&nbsp;all&nbsp;of&nbsp;us
          <AnimatedArrow hoverTrigger={ContactLink} />
        </ContactLink>
      </ContactLinkAlign>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query IndexQuery {
    datoCmsStartPage {
      heroTitle
      heroBackgroundType
      heroBackgroundColor {
        hex
      }
      heroBackgroundMedia {
        fluid(maxWidth: 2560, imgixParams: { fm: "jpg", auto: "compress" }, sizes: "375, 750, 850, 1024, 1700, 2048, 2560") {
          ...GatsbyDatoCmsFluid
        }
        url
      }
      heroVideoPoster {
        fixed(width: 1440, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFixed
        }
      }
      contacts {
        jobTitle
        name
        titleStatus
        phone
        mail
        image {
          fluid(maxWidth: 1030, imgixParams: { fm: "jpg", auto: "compress" }, sizes: "160, 320, 560, 850, 1030") {
            ...GatsbyDatoCmsFluid
          }          
        } 
      }
      contactsLink
      seoMetaTags {
        tags
      }
    }
  }
`