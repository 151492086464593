import React from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from '../../layout/globalStyles'
import theme from '../../layout/theme'
import CSSReset from '../../layout/reset'
import RTEStyles from '../../layout/rteStyles'
import SEO from '../SEO'
import Header from '../Header/header'
import MenuMobile from '../MenuMobile/menu-mobile'
import Footer from '../Footer/footer'

export default ({ children, seo, template }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <CSSReset />
        <GlobalStyles />
        <RTEStyles />
        <SEO
          seoTags={seo}
          bodyClass={`template-${template}`}
        />
        {/* DOM structure and classes needed for sticky footer */}
        <div className="site-wrapper">
          <div className="content">
            <Header theme={template === 'projects' ? 'light' : 'dark'} />
            <MenuMobile />
            <main>{children}</main>
          </div>
          <Footer />
        </div>
      </>
    </ThemeProvider>
  )
}