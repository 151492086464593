import React from 'react'
import { graphql } from 'gatsby'
import { StyledImg, Wrapper } from './styles'
import theme from '../../../layout/theme'

const InlineImages = ({ data }) => {
  const { images } = data;
  const { breakpoints: { phone, large } } = theme

  const sizes = images.length > 1
    ? `(max-width: ${phone}px) calc(50vw - ${theme.pagepaddingMobile}), (max-width: ${large}px) calc(50vw - ${theme.pagepaddingDesktop}), calc(33.5vw - (${theme.pagepaddingDesktop} / 2) - ${theme.gutterSmall})`
    : `(max-width: ${phone}px) calc(100vw - (${theme.pagepaddingMobile} * 2)), (max-width: ${large}px) calc(100vw - (${theme.pagepaddingDesktop} * 2)), calc(67vw - (${theme.pagepaddingDesktop} / 2))`

  return (

    <Wrapper>
      { images && images.map((image) => {
        const responsiveImage = {
          ...image.fluid,
          sizes
        }
        return (
          <StyledImg
            key={image.originalId}
            fluid={responsiveImage}
            alt={image.alt}
          />
        )
      })}
    </Wrapper>
  )
}

export default InlineImages


export const INLINE_IMAGES_FRAGMENT = graphql`
  fragment InlineImagesFragment on DatoCmsInlineImage {
    id
    images {
      originalId
      alt
      fluid(maxWidth: 1280, imgixParams: { fm: "jpg", auto: "compress" }, sizes: "375, 750, 850, 1024, 1440, 1700, 2048, 2560") {
        ...GatsbyDatoCmsFluid
      }          
    } 
  }
`