import React, { useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import lottie from 'lottie-web'
import {
  Grid,
  GridItem,
  MediaItem,
  CircleText,
  MediaImageWrapper,
  StyledVideo,
  BusinessModelItem,
  BusinessModelTitle,
  BusinessModelDescription,
  CTA1Item,
  CTA2Item,
  CTA2Title,
  CTA2Description,
  CTA2Link,
  CTA2Footer,
  FeaturedCaseItem,
  FeaturedCaseContent,
  FeaturedCaseTitle,
  FeaturedCaseClient,
  FeaturedCaseAnimatedArrow
} from './styles'
import AnimatedArrow from '../AnimatedArrow/animated-arrow'
import theme from '../../layout/theme'

const StartGrid = () => {
  const movingTextElement = useRef()
  const result = useStaticQuery(query).datoCmsStartPage
  const { hex } = result.gridCta1Background || {}

  const {
    businessModelTitle,
    businessModelDescription,
    gridMediaSquareBackgroundType,
    gridMediaSquareMedia,
    gridCta1,    
    gridCta2Title,
    gridCta2Description,
    gridCta2Link,
    gridFeaturedCase: {
      title: featureCaseTitle,
      slug: featureCaseSlug,
      client: featureCaseClient
    },
    gridFeaturedCaseBackgroundType,
    gridFeaturedCaseMedia
  } = result

  const jsonGridMediaSquareBackgroundType = JSON.parse(gridMediaSquareBackgroundType);
  const jsonGridFeaturedCaseBackgroundType = JSON.parse(gridFeaturedCaseBackgroundType);

  const mediaItemBackgroundType = Array.isArray(jsonGridMediaSquareBackgroundType) && jsonGridMediaSquareBackgroundType.length > 0 ? jsonGridMediaSquareBackgroundType[0] : 'image'
  const featuredCaseItemBackgroundType = Array.isArray(jsonGridFeaturedCaseBackgroundType) && jsonGridFeaturedCaseBackgroundType.length > 0 ? jsonGridFeaturedCaseBackgroundType[0] : 'image'

  const { breakpoints: { tablet } } = theme

  const squareImage = mediaItemBackgroundType === 'image' ? {
    ...gridMediaSquareMedia?.fluid,
    sizes: `(max-width: ${tablet}px) 100vw, 40vw`
  } : {}

  const caseImage = featuredCaseItemBackgroundType === 'image' ? {
    ...gridFeaturedCaseMedia?.fluid,
    sizes: `(max-width: ${tablet}px) 100vw, 60vw`
  } : {}

  // Run text animation when component is loaded
  useEffect(() => {
    fetch('text.json')
      .then((response) => response.json())
      .then((json) => {
      lottie.loadAnimation({
        container: movingTextElement.current, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'text.json' // the path to the animation json
      })
    })
  }, [])
  
  return (
    <Grid>
      <BusinessModelItem>
        <BusinessModelTitle className="rte" dangerouslySetInnerHTML={{ __html: businessModelTitle }} />
        <BusinessModelDescription className="rte" dangerouslySetInnerHTML={{ __html: businessModelDescription }} />
      </BusinessModelItem>
      <MediaItem>
        <CircleText 
          ref={movingTextElement}
        />
        {mediaItemBackgroundType === 'image' ? (
          <MediaImageWrapper fluid={squareImage} />
          ) : null

        }

        {mediaItemBackgroundType === 'video' ? (
          <StyledVideo
            url={gridMediaSquareMedia?.url}
            caption="Video caption"
            autoPlay={true}
          />
        ) : null}
      </MediaItem>
      <CTA1Item className="rte" dangerouslySetInnerHTML={{ __html: gridCta1 }}/>
      <CTA2Item>
        <CTA2Link to={gridCta2Link}>
          <CTA2Title className="rte" dangerouslySetInnerHTML={{ __html: gridCta2Title }} />
          <CTA2Footer>
            <CTA2Description className="rte" dangerouslySetInnerHTML={{ __html: gridCta2Description }} />
            <AnimatedArrow hoverTrigger={CTA2Link} />
          </CTA2Footer>
        </CTA2Link>
      </CTA2Item>
      <FeaturedCaseItem>
        {featuredCaseItemBackgroundType === 'image' ? (
          <MediaImageWrapper fluid={caseImage} />
         ) : null
        }

        {featuredCaseItemBackgroundType === 'video' ? (
          <StyledVideo
            url={gridFeaturedCaseMedia?.url}
            caption="Video caption"
            autoPlay={true}
          />
        ) : null}

        <FeaturedCaseContent to={`/projects/${featureCaseSlug}`}>
          <FeaturedCaseClient>{featureCaseClient}</FeaturedCaseClient>
          <FeaturedCaseTitle>{featureCaseTitle}</FeaturedCaseTitle>
          <FeaturedCaseAnimatedArrow hoverTrigger={FeaturedCaseContent} />
        </FeaturedCaseContent>
      </FeaturedCaseItem>
    </Grid>
  )
}
export default StartGrid

export const query = graphql`
  query StartGridQuery {
    datoCmsStartPage {
      businessModelTitle
      businessModelDescription
      gridMediaSquareBackgroundType
      gridMediaSquareMedia {
        url
        fluid(maxWidth: 2048, imgixParams: { fm: "jpg", auto: "compress" }, sizes: "375, 512, 750, 1024, 1440, 1900, 2048") {
          ...GatsbyDatoCmsFluid
        }
      }
      gridCta1
      gridCta1Background {
        hex
      }
      gridCta2Title
      gridCta2Description
      gridCta2Link
      gridFeaturedCase {
        title
        slug
        client
      }
      gridFeaturedCaseBackgroundType
      gridFeaturedCaseMedia {
        url
        fluid(maxWidth: 2560, imgixParams: { fm: "jpg", auto: "compress" }, sizes: "375, 512, 750, 1024, 1440, 2048, 2560") {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`