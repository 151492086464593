import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import ProjectListingHero from '../../components/ProjectListingHero/project-listing-hero'
import FeaturedProjectsGrid from '../../components/FeaturedProjectsGrid/featured-projects-grid'

const ServiceArea = ({ data, pageContext }) => {
  const {
    heroTitle,
    showFilters,
    seoMetaTags
  } = data.datoCmsProjectPage

  const { edges: projectEdges } = data.allDatoCmsProject
  const { edges: allProjectIdsEdges } = data.allProjectIds
  const { edges: serviceAreaEdges } = data.allDatoCmsServiceArea

  const modifiedProjects = projectEdges.map((item) => ({ ...item.node }))
  const modifiedProjectIds = allProjectIdsEdges.map((item) => item.node.id)
  const modifiedServiceAreas = serviceAreaEdges.map((item) => ({ ...item.node }))

  return (
    <Layout
      seo={seoMetaTags.tags}
      template="projects"
    >
      <ProjectListingHero
        title={heroTitle}
        tags={modifiedServiceAreas}
        activeTag={pageContext.serviceAreaName}
      />
      <FeaturedProjectsGrid
        projects={modifiedProjects}
        projectIds={modifiedProjectIds}
      />
    </Layout>
  )
}

export default ServiceArea

export const query = graphql`
  query ServiceAreaTemplateQuery($id: [String]) {
    datoCmsProjectPage {
      heroTitle
      showFilters
      seoMetaTags {
        tags
      }    
    }
    allDatoCmsProject(filter: {serviceFields: {elemMatch: {originalId: {in: $id }}}}, sort: { fields: [position], order: ASC }) {
      edges {
        node {
          ...FeaturedProjectFragment
        }
      }
    }
    allProjectIds: allDatoCmsProject(sort: { fields: [position], order: DESC }) {
      edges {
        node {
          id
        }
      }
    }
    allDatoCmsServiceArea {
      edges {
        node {
          id
          serviceAreaName
        }
      }
    }    
  }  
`