import React from 'react'
import {
  Wrapper,
  Grid,
  GridItem
} from './styles'
import FeaturedProject from '../FeaturedProject/featured-project'

const FeaturedProjectsGrid = ({ 
  projects,
  projectIds = [],
  className,
}) => {

  return (
    <Wrapper className={className}>
        <Grid>
          {projects.map((item) => (
            <GridItem key={item.id}>
              <FeaturedProject
                project={item}
                allProjectIds={projectIds}
              />
            </GridItem>
          ))}
        </Grid>
    </Wrapper>
  )
}

export default FeaturedProjectsGrid