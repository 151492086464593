import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Nav,
  StyledHalign,
  Content,
  NavList,
  NavItem,
  Info,
  InfoItem,
  TypeFormButton
} from './styles'
import CustomLink from '../CustomLink'

const MenuMobile = () => {
  const {
    links,
    content
  } = useStaticQuery(query).datoCmsHeader

  return (
    <Nav>
      <StyledHalign>
        <Content>
          <NavList>
            {links.map((link) => (
              <NavItem
                key={link.title}
                onClick={() => {
                  document.body.classList.remove('menu-open')
                }}
              >
                <CustomLink to={link.url}>
                  {link.title}
                </CustomLink>
              </NavItem>
            ))}
          </NavList>
          <Info>
          {content.map((info, i) => (
            <InfoItem key={i} dangerouslySetInnerHTML={{ __html: info.text}} />
          ))}
          <TypeFormButton id="GzzlALgN">
            Get quote
          </TypeFormButton>
          </Info>
        </Content>
      </StyledHalign>
    </Nav>
  )
}

MenuMobile.defaultProps = {
  menuItems: []
}

export default MenuMobile

export const query = graphql`
  query MenuMobileQuery {
    datoCmsHeader {
      links {
        title
        url
      }      
      content {
        text
      }
    }    
  }
`
