import React from 'react'
import {
  Wrapper,
  ImageWrapper,
  StyledVideo,
  Inner,
  Content,
  HeadingGroup,
  Heading,
  Arrow,
} from './styles'
import TextTags from '../TextTags/text-tags'

const HeroTags = ({
  title,
  backgroundMedia,
  backgroundType: bgType,
  backgroundColor,
  slug,
  tags
}) => {

  const jsonBgType = JSON.parse(bgType)
  const backgroundType = Array.isArray(jsonBgType) && jsonBgType.length > 0 ? jsonBgType[0] : 'color'

  return (
    <Wrapper
      backgroundColor={backgroundType === 'color' && backgroundColor}
    >

      {backgroundType === 'image' ? <ImageWrapper fluid={backgroundMedia?.fluid} /> : null}

      {backgroundType === 'video' ? (
          <StyledVideo
            url={backgroundMedia?.url}
            caption="Video caption"
            autoPlay={true}
          />
      ) : null}      

      <Inner>
        <Arrow />
        <Content>
          <HeadingGroup>
            <Heading dangerouslySetInnerHTML={{ __html: title }} />
          </HeadingGroup>
          <TextTags tags={tags} />
        </Content>
      </Inner>
    </Wrapper>
  )
}

export default HeroTags