import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import {
  Halign,
  breakpoint,
  CaseHeading1Styles,
  CaseHeading2Styles,
  truncateOverflow
} from '../../layout/mixins'
import ArrowIcon from '../../layout/icons/long-arrow.svg'
import NextCaseArrowIcon from '../../layout/icons/next-case-arrow.svg'
import CustomLink from '../CustomLink'
import Video from '../Video'

export const Wrapper = styled.section`
  position: relative;
  height: 80vh;

  ${breakpoint.up('tablet')`
    min-height: 500px;
  `}

  ${({ backgroundColor }) => backgroundColor && `
    background-color: ${backgroundColor}; 
  `}
`

export const ImageWrapper = styled(BackgroundImage)`
  height: 80vh;

  ${breakpoint.up('tablet')`
    min-height: 500px;
  `}
`

export const StyledVideo = styled(Video)`
    ${breakpoint.up('max')`
      overflow: hidden;

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
      }
    `}
`

export const Inner = styled(Halign)`
  position: absolute;
  top: ${({ theme }) => theme.headerheight};
  height: calc(100% - ${({ theme }) => theme.headerheight});
  display: flex;
  align-items: flex-end;
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    padding-left: ${({ theme }) => theme.gutter};
  `}
`

export const Number = styled.span`
  ${CaseHeading2Styles}
  display: block;
  margin-bottom: ${({ theme }) => theme.gutterLarge};
  color: ${({ theme }) => theme.textcolorSecondary};
`

export const HeadingGroup = styled.div``

export const Heading = styled.h1`
  ${CaseHeading1Styles}
  color: ${({ theme }) => theme.textcolorSecondary};
  ${truncateOverflow(3, true)}
`

export const Announcement = styled.div`
  position: absolute;
  width: 14rem;
  color: ${({ theme }) => theme.textcolorSecondary};
  font-size: 18px;
  line-height: 22px; 
  top: 9rem;
  display: none;

  ${breakpoint.up('tablet')`
    display: block;
    left: ${({ theme }) => theme.gutter};
  `}

  @media screen and (max-height: 700px) {
    display: none;
  }

  p {
    font-size: 18px;
    line-height: 22px;  
  }

  a {
    color: white;
  }
`

export const Arrow = styled(ArrowIcon)`
  transform-origin: bottom center;
  position: absolute;
  left: ${({ theme }) => `calc(${theme.pagepaddingDesktop} - 0.45rem)`};
  top: -${({ theme }) => theme.headerheight};
  height: ${({ theme }) => `calc(100% + ${theme.headerheight} - ${theme.gutter})`};
  fill: ${({ theme }) => theme.colorWhite};
  width: 16px;
  display: none;

  ${breakpoint.up('tablet')`
    display: block;
  `}
`

export const NextCase = styled.div`
  ${breakpoint.down('phone')`
    display: none;
  `}

  position: absolute;
  right: ${({ theme }) => theme.gutterMedium};
  top: 50%;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.textcolorSecondary};
`

export const Numbers = styled.div`
  ${breakpoint.down('phone')`
    display: none;
  `}
`

export const StyledCustomLink = styled(CustomLink)`
  display: flex;
`

export const CurrentCase = styled.span``

export const Total = styled.span``

export const NextCaseArrow = styled(NextCaseArrowIcon)`
  fill: ${({ theme }) => theme.textcolorPrimary};

  ${breakpoint.up('phone')`
    fill: ${({ theme }) => theme.textcolorSecondary};
    margin-left: ${({ theme }) => theme.gutter};
  `} 
`
