import styled from 'styled-components'

export const Background = styled.article`
  background: ${({ theme }) => theme.colorBlack};
  padding: ${({ theme }) => theme.gutter};
`

export const PRE = styled.pre`
  color: ${({ theme }) => theme.colorAccent};
  font-family: 'Roboto Mono', mono;
  font-size: ${({ theme }) => theme.fontsizeSmall};
`