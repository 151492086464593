import React from "react"
import PropTypes from "prop-types"
import {
  Wrapper,
  Inner,
  Grid,
  GridItem1,
  GridItem2,
  Jobs,
  JobItem,
  JobTitle,
  Arrow,
} from "./styles"
import { Link } from "gatsby"

const JobsList = ({ jobs }) => {
  return (
    <Wrapper>
      <Inner>
        <Grid>
          <GridItem1></GridItem1>
          <GridItem2>
            <Jobs>
              {jobs &&
                jobs.map(job => (
                  <Link key={job.id} to={`/jobs/${job.slug}`}>
                    <JobItem>
                      <Arrow />
                      <JobTitle>{job.title}</JobTitle>
                    </JobItem>
                  </Link>
                ))}
            </Jobs>
          </GridItem2>
        </Grid>
      </Inner>
    </Wrapper>
  )
}

JobsList.propTypes = {
  jobs: PropTypes.array.isRequired,
}

export default JobsList
