import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SimpleHero from '../../components/SimpleHero/simple-hero'
import InfoGrid from '../../components/InfoGrid/info-grid'
import ContactGrid from '../../components/ContactGrid/contact-grid'
import Divider from '../../components/Divider/divider'

const Contact = ({ data }) => {
  const {
    datoCmsContact: {
      heroTitle,
      contentOffice,
      contentNewBusiness,
      contentInternship,
      contacts,
      seoMetaTags
    }
  } = data
  return (
    <Layout
      seo={seoMetaTags.tags}
      template="contact"
    >
      <SimpleHero title="Meet us" />
      <InfoGrid
        title1="The office"
        text1={false}
        title2={false}
        text2={contentOffice}
      />
      <InfoGrid
        title1="Internship"
        text1={contentInternship}
        title2="New business"
        text2={contentNewBusiness}
      />
      <Divider bottom={false} />
      <ContactGrid items={contacts} />
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactQuery {
    datoCmsContact {
      heroTitle
      contentOffice
      contentNewBusiness
      contentInternship
      contacts {
        jobTitle
        name
        titleStatus
        phone
        mail
        image {
          fluid(maxWidth: 900, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }          
        } 
      }
      seoMetaTags {
        tags
      }      
    }   
  }
`
