import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import BlockRenderer from '../../components/BlockRenderer/block-renderer'
import HeroTags from '../../components/HeroTags/hero-tags'
import { StyledLink, Footer, StyledH2, StyledInsightsGrid } from './styles'
import CopyLink from '../../components/CopyLink/copy-link'
import { generateTags } from '../../utils/helpers'
import { Helmet } from 'react-helmet'

const SingleInsight = ({ data }) => {
  const {
    title,
    seoMetaTags,
    pageContent,
    heroBackgroundMedia,
    heroBackgroundType,
    heroBackgroundColor,
    tags,
    slug,
    meta: {firstPublishedAt},
    similarInsights
  } = data.datoCmsInsight

  const heroTags = generateTags(tags, pageContent, firstPublishedAt)

  return (
    <Layout
      seo={seoMetaTags.tags}
      template="single-project"
    >
      <Helmet>
        <html lang="sv" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link rel="preload" href="https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap" as="style" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap" rel="stylesheet" />
      </Helmet>
      <HeroTags
        title={title}
        backgroundMedia={heroBackgroundMedia}
        backgroundType={heroBackgroundType}
        backgroundColor={heroBackgroundColor?.hex}
        slug={slug}
        tags={heroTags}
      />

        {pageContent && pageContent.map((block) => (
          <BlockRenderer key={block.id} block={block} />
        ))}

      <Footer>
        <CopyLink toCopy={`https://sould.se/insights/${slug}`} /><StyledLink to="/contact">Get in touch</StyledLink>
      </Footer>
      { similarInsights.length > 0 && (
        <>
        <StyledH2>Similar Insights</StyledH2>
        <StyledInsightsGrid
          insights={similarInsights}
          columns={3}
        />
      </>
      )}
    </Layout>
  )
}

export default SingleInsight

export const query = graphql`
  query SingleInsightQuery($slug: String!) {
    datoCmsInsight(slug: {eq: $slug}) {
      title
      slug
      heroBackgroundType
      heroBackgroundColor {
        hex
      }
      tags {
        title
      }
      meta {
        firstPublishedAt
      }
      heroBackgroundMedia {
        fluid(maxWidth: 2560, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        url
      }
      pageContent {
        __typename
        ...RichTextContentFragment
        ...DatoCmsCodeFragment
        ...FullWidthImageFragment
        ...InlineImagesFragment
      }
      seoMetaTags {
        tags
      }
      similarInsights {
        ...InsightCardFragment
      }
    }
  }
`
