import styled from 'styled-components'
import CustomLink from '../CustomLink'
import { breakpoint } from '../../layout/mixins'

export const NavBar = styled.nav`
  height: 100%;
  align-items: center;
  display: none;

  ${breakpoint.up('desktop')`
    display: flex;
  `}
`

export const NavLink = styled(CustomLink)`
  height: 100%;
  width: ${({ theme }) => theme.pagepaddingDesktop};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textcolorSecondary};
  border-right: ${({ theme }) => `${theme.borderSize} solid ${theme.textcolorSecondary}`};
  padding: ${({ theme }) => theme.gutterSmall};
  font-size:  1.12rem;

  &:first-child {
    border-right: ${({ theme }) => `${theme.borderSize} solid transparent`};
  }

  .header--light & {
    color: ${({ theme }) => theme.textcolorPrimary};
    border-right: ${({ theme }) => `${theme.borderSize} solid ${theme.textcolorPrimary}`};
  }

  .header--light &:first-child {
    border-right: ${({ theme }) => `${theme.borderSize} solid transparent`}; 
  }
`