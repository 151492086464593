import React from 'react'
import { graphql } from 'gatsby'
import { addZero } from '../../utils/helpers'
import {
  Wrapper,
  Image,
  Content,
  Header,
  HeaderContent,
  Title,
  Number,
  StyledBackgroundImage,
  StyledVideo,
  Footer,
  FooterBackground,
  FooterContent,
  FooterGroup,
  FooterItem,
  FooterItemTitle,
  Arrow
} from './styles'

const FeaturedProject = ({
  project,
  allProjectIds
}) => {
  const backgroundType = project.thumbnail.isImage ? 'image' : 'video'
  let number = ''

  if (allProjectIds.length > 0) {
    number = allProjectIds.indexOf(project.id) + 1
  }

  return (
    <Wrapper>
      <Image to={`/projects/${project.slug}`}>
        <Content>
          {backgroundType === 'image' ? <StyledBackgroundImage style={{ position: 'absolute' }} fluid={project.thumbnail.fluid} /> : null}

          {backgroundType === 'video' ? (
            <StyledVideo
              url={project.thumbnail.url}
              caption="Video caption"
              autoPlay
            />
          ) : null}

          <Header>
            <HeaderContent>
              <Number>
                .
                {addZero(number)}
              </Number>
              <Title>{project.title}</Title>
            </HeaderContent>
          </Header>
          <Footer>
            <FooterBackground />
            <FooterContent>
              <FooterGroup>
                <FooterItemTitle>Client</FooterItemTitle>
                <FooterItem>{project.client}</FooterItem>
              </FooterGroup>
              <FooterGroup>
                <FooterItemTitle>Balance between</FooterItemTitle>
                {project.serviceFields.map((item) => <FooterItem key={item.serviceAreaName}>{item.serviceAreaName}</FooterItem>)}
              </FooterGroup>
              <FooterGroup>
                <Arrow />
              </FooterGroup>
            </FooterContent>
          </Footer>
        </Content>
      </Image>
    </Wrapper>
  )
}

export default FeaturedProject

export const FEATURED_PROJECT_FRAGMENT = graphql`
  fragment FeaturedProjectFragment on DatoCmsProject {
    id
    title
    serviceFields {
      serviceAreaName
    }
    client
    slug
    position
    thumbnail {
      fluid(maxWidth: 1280, imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
      url
      isImage
    }
  }
`