import styled from "styled-components"
import { Halign, BlockPaddingTop, breakpoint } from "../../layout/mixins"
import ArrowIcon from "../../layout/icons/sould-arrow.svg"
import theme from "../../layout/theme"

export const Wrapper = styled.section`
  padding-top: ${theme.gutterMedium};
`

export const Inner = styled(Halign)``

export const Grid = styled.div`
  display: flex;
  flex-direction: column;

  margin: 1px solid red;

  ${breakpoint.up("tablet")`
    flex-direction: row;    
  `}
`
const GridItem = styled.div`
  width: 100%;
  flex: 1;

  ${breakpoint.up("tablet")`
    width: 50%;
  `}

  ${breakpoint.up("large")`
    width: auto;
  `}
`

export const GridItem1 = styled(GridItem)``

export const GridItem2 = styled(GridItem)`
  ${breakpoint.up("large")`
    flex: 2;
  `}
`

export const Jobs = styled.ul`
  border-top: ${theme.borderSize} solid ${theme.colorBlack};
`

export const JobItem = styled.li`
  padding: 40px 0;
  border-bottom: ${theme.borderSize} solid ${theme.colorBlack};
  display: flex;
  align-items: center;
`

export const Arrow = styled(ArrowIcon)`
  margin-bottom: 4px;
  width: 40px;
  height: 16px;

  ${breakpoint.up("tablet")`
    width: 60px;
    height: 25px;
  `}
`

export const JobTitle = styled.h2`
  font-size: 1.6rem;

  ${JobItem}:hover & {
    transform: translateX(20px);
  }
  transition: transform ${theme.transitionRegular};

  ${breakpoint.up("tablet")`
    font-size: 1.54rem;
  `}

  ${breakpoint.up("large")`
    font-size: 2.14rem;
  `}
`
