import React from 'react'
import {
  NavBar,
  NavLink
} from './styles'

const Menu = ({ menuItems }) => {
  return (
    <NavBar>
      {menuItems.map((item) => {
        return <NavLink key={item.url} to={item.url}>{item.title}</NavLink>
      })}
    </NavBar>
  )
}

export default Menu
