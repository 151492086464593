import React from 'react'
import { graphql } from 'gatsby'
import {
  RTE
} from './styles'
import { InsightHalign } from '../../../layout/mixins'

const RichText = ({ data }) => {
  const { content } = data;
  const modifiedContent = content.replace(/<span style="text-decoration: line-through;">(.*)<\/span>/gm, '<mark>$1</mark>')

  return (
    <InsightHalign>
      <RTE className="rte" dangerouslySetInnerHTML={{ __html: modifiedContent }} />
    </InsightHalign>
  )
}

export default RichText


export const RICH_TEXT_CONTENT_FRAGMENT = graphql`
  fragment RichTextContentFragment on DatoCmsRichTextContent {
    id
    content
  }
`